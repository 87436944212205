import * as React from 'react';
import {Component} from 'react';
import {observer} from 'mobx-react';
import {action, makeObservable, observable} from "mobx";
import axios from 'axios';

import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import {FormDialogWrapper} from "../components/form_dialog_wrapper";
import ChangePasswordForm from "../components/forms/change_password_form";
import {UserContext} from "./contexts/user_ctx";
import {Box} from "@material-ui/core";
import {Snackbars} from "./contexts/snackbar_ctx";

interface ChangePasswordProps {
	notifications: Snackbars;
}



@observer
export default class ChangePassword extends Component<ChangePasswordProps, {}> {
	static contextType = UserContext; // To use multiple context use func component
	processing: boolean = false;

	constructor( props: any, context: any ) {
		super( props, context );

		makeObservable(this, {
			processing: observable,
		});
	}

	private changePasswordDialogRef: FormDialogWrapper | null = null;

	public render() {
		return (
			<Box>
				<IconButton onClick={this.changePassword}>
					<EditIcon />
				</IconButton>

				<FormDialogWrapper
					ref={this.setChangePasswordDialogRef}
					name="Change password"
					form={ChangePasswordForm}
					onSubmit={this.processChangePassword}
					onCancel={this.onHide}
					processing={this.processing}
				/>
			</Box>
		);
	}

	private setChangePasswordDialogRef = ( ref: FormDialogWrapper ) => {
		this.changePasswordDialogRef = ref;
	};

	private changePassword = () => {
		this.changePasswordDialogRef?.show();
	};

	private onHide = async () => {
		this.changePasswordDialogRef?.hide( null );
	};

	private processChangePassword = async ( newPassword: string, currentPassword: string ) => {
		if( !this.processing ) {
			try {
				action( () => {
					this.processing = true;
				} )()

				const resp = await axios( {
					method: 'patch',
					url: '/api/v1/users/change-password',
					data: {
						password: newPassword,
						current_password: currentPassword,
					}
				} );

				if( resp.data.success ) {
					action( () => {
						this.props.notifications.showSnackbar( "Password has been changed successfully" );
						this.changePasswordDialogRef?.hide( null, true );
					} )()
				} else {
					throw new Error( "Invalid response" );
				}
			} catch( err ) {
				console.error(err);
				this.props.notifications.showSnackbar( "Can not change password", "error" );
			} finally {
				action( () => {
					this.processing = false;
				} )()
			}
		}
	};

}
import * as React from 'react';
import {FunctionComponent, useContext, useState} from 'react';
import {observer} from 'mobx-react';
import {useHistory} from "react-router-dom";

import PostAddIcon from '@material-ui/icons/PostAdd';
import {BaseScreen} from "../../components/base_screen";
import axios from "axios";
import {action, observable} from "mobx";
import {SnackbarsContext} from "../contexts/snackbar_ctx";
import TenderForm from "../../components/forms/tender_form";


const TenderCreator: FunctionComponent<{}> = observer( () => {
	const notifications = useContext(SnackbarsContext);
	const history = useHistory();
	const [store] = useState(() =>
		observable( {
			processing: false,
			result: undefined as undefined | number,
		} )
	);

	const onSubmit = async ( params: any ) => {
		action( () => {
			store.processing = true;
		} )()
		try {
			const { data } = await axios( {
				method: 'post',
				url: '/api/v1/tender',
				data: {
					...params,
				}
			} );

			action( () => {
				store.result = data.result;
			} )()

			notifications.showSnackbar( "Tender crated", "success" );
			history.push("/");
		} catch (e) {
			notifications.showSnackbar( "Unable to create, try again later", "error" );
		} finally {
			action( () => {
				store.processing = false;
			} )()
		}
	};

	return (
		<BaseScreen
			icon={PostAddIcon}
			title="Add new tender"
			breadCrumbs={[
				{
					link: "/",
					title: "Tenders",
				},
				{
					link: window.location.pathname,
					title: "Create new tender",
				},
			]}
		>
			<TenderForm
				onSubmit={onSubmit}
				processing={store.processing}
				result={store.result}
			/>
		</BaseScreen>
	);
} );

export default TenderCreator;

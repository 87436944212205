import React from 'react';
import {Theme, withStyles} from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';

export const LightTooltip = withStyles((theme: Theme) => ({
	tooltip: {
		backgroundColor: theme.palette.info.main,
		color: theme.palette.common.white,
		boxShadow: theme.shadows[2],
		fontSize: 16,
		maxWidth: 320,
	},
}))(Tooltip);
import * as React from 'react';
import {FunctionComponent, useContext, useEffect, useRef, useState} from 'react';
import {observer} from 'mobx-react';
import {useParams} from "react-router-dom";


import CalcIcon from '@material-ui/icons/Tune';
import {BaseScreen} from "../../components/base_screen";
import axios from "axios";
import {action, observable} from "mobx";
import CalcForm from "../../components/forms/calc_form";
import {SnackbarsContext} from "../contexts/snackbar_ctx";
import Calculation from "../../models/calculation";
import PrintIcon from '@material-ui/icons/Print';
import Tender from "../../models/tender";


const HistoryDetails: FunctionComponent<{}> = observer( () => {
	const notifications = useContext(SnackbarsContext);
	const { id, calcId } = useParams() as any;
	const [store] = useState(() =>
		observable( {
			tender: undefined as undefined | Tender,
			loading: false,
			loaded: false,
			processing: false,
			calc: undefined as undefined | Calculation,
		} )
	);

	const endRef: any = useRef(null)

	const scrollToBottom = () => {
		endRef.current?.scrollIntoView({ behavior: "smooth" })
	}

	useEffect( () => {
		if( calcId ) {
			( async () => {
				action( () => {
					store.loading = true;
				} )()
				try {
					const { data } = await axios( {
						method: 'post',
						url: '/api/v1/calculate/details',
						data: {
							id: calcId
						}
					} );

					action( () => {
						store.calc = new Calculation( data.details );
						store.loaded = true;
					} )()

					const tender = ( await axios( {
						method: 'post',
						url: '/api/v1/tender/details',
						data: {
							id,
						}
					} ) ).data.details
					action( () => {
						store.tender = new Tender( tender );
						setTimeout(  scrollToBottom, 0);
					} )()
				} catch (e) {
					notifications.showSnackbar( "Unable to load calculations details", "error" );
				} finally {
					action( () => {
						store.loading = false;
					} )()
				}
			})().then();
		}
	}, [calcId] )

	const getPdf = async () => {
		const resp = await axios( {
			method: 'post',
			url: '/api/v1/calculate/pdf',
			data: {
				id: calcId,
			}
		} );

		const blob = new Blob([new Uint8Array(resp.data.file.data)], {type: "application/pdf"});
		const url = URL.createObjectURL(blob);
		let a: any = document.createElement("a");
		document.body.appendChild(a);
		a.style = "display: none";
		a.href = url;
		a.download = `tender-offer-${calcId}-date-${(new Date()).getTime()}.pdf`;
		a.click();
		window.URL.revokeObjectURL(url);
	}

	return (
		<BaseScreen
			icon={CalcIcon}
			title={store.calc?.name}
			actionIcon={PrintIcon}
			onAction={getPdf}
			loading={store.loading}
			loaded={store.loaded}
			breadCrumbs={[
				{
					link: "/",
					title: "Tenders",
				},
				{
					link: `/tenders/${id}`,
					title: store.tender ? store.tender.name : id,
				},
				{
					link: `/tenders/${id}/calculations`,
					title: "Calculations",
				},
				{
					link: window.location.pathname,
					title: store.calc?.name,
				},
			]}
		>
			{
				store.calc && (
					<CalcForm
						processing={store.processing}
						calc={store.calc}
						readonly={true}
					/>
				)
			}
			<div ref={endRef} />
		</BaseScreen>
	);
} );

export default HistoryDetails;

import * as _ from 'lodash';
import * as React from 'react';
import {Component} from 'react';
import {observer} from 'mobx-react';
import {action, makeObservable, observable} from "mobx";
import axios from 'axios';


import GavelIcon from '@material-ui/icons/Gavel';
import {BaseScreen} from "../../components/base_screen";
import {SnackbarsContext} from "../contexts/snackbar_ctx";
import Tender from "../../models/tender";
import ActionListIcon from "@material-ui/icons/MoreVert";
import TendersTable from "../../components/tenders_table";


export type Order = 'asc' | 'desc';
export type SearchFilters = { [key:string]: string };

@observer
class TendersList extends Component<any, any> {
	static contextType = SnackbarsContext; // To use multiple context use func component

	tenders: Tender[] = [];
	filters: SearchFilters = {};
	loading: boolean = false;
	count: number = 0;
	loaded: boolean = true;

	orderBy: string =  'id';
	order: Order = 'asc';
	page = 0;
	rows = 10;

	constructor( props: any, context: any ) {
		super( props, context );

		makeObservable(this, {
			tenders: observable,
			loading: observable,
			loaded: observable,
		});
	}

	public async UNSAFE_componentWillMount() {
		await this.loadCalcs();
	}

	public render() {
		const hasTenders = ( this.tenders && this.tenders.length ) || _.keys(this.filters).length > 0;

		return (
			<BaseScreen
				icon={GavelIcon}
				title="Tenders list"
				actionIcon={ActionListIcon}
				onAction={[
					{ onAction: this.create, name: "Create new" },
					{ onAction: this.getCsv, name: "Export to csv" },
				]}
				loading={this.loading}
				loaded={this.loaded}
				emptyListMessage={this.loaded && !hasTenders ? 'Tenders list is empty' : undefined}
			>
				{hasTenders && <TendersTable
					tenders={this.tenders}
					count={this.count}
					rows={this.rows}
					changePage={this.changePage}
					changeRowsPerPage={this.changeRowsPerPage}
					changeOrder={this.changeOrder}
					page={this.page}
					orderBy={this.orderBy}
					order={this.order}
					onFilter={this.onFilter}
					filters={this.filters}
				/>}
			</BaseScreen>
		);
	}

	private create = () => {
		this.props.history.push( `/create` );
	};

	private changePage = (event: unknown, newPage: number) => {
		this.page = newPage;
		this.loadCalcs().then();
	};

	private changeRowsPerPage = (event: any) => {
		this.rows = Number(event.target.value);
		this.page = 0;
		this.loadCalcs().then();
	};

	private getCsv = async () => {
		const resp = await axios( {
			method: 'post',
			url: '/api/v1/tender/export',
			data: {
			}
		} );

		const blob = new Blob([new Uint8Array(resp.data.file.data)], {type: "application/csv"});
		const url = URL.createObjectURL(blob);
		let a: any = document.createElement("a");
		document.body.appendChild(a);
		a.style = "display: none";
		a.href = url;
		a.download = `tenders-date-${(new Date()).getTime()}.csv`;
		a.click();
		window.URL.revokeObjectURL(url);
	}

	private changeOrder = (cellId: any) => {
		if( this.orderBy === cellId ) {
			this.order = this.order === 'asc' ? 'desc' : 'asc';
		} else {
			this.orderBy = cellId;
			this.order = 'asc'
		}

		this.loadCalcs().then();
	};

	private onFilter = (name: string, value: string) => {
		if( !_.isEmpty(value) ) {
			this.filters[name] = value;
		} else {
			delete this.filters[name];
		}

		this.loadCalcs().then();
	};

	private loadCalcs = async ( page = 0, orderBy = 'id', order = 'desc' ) => {
		try {
			action( () => {
				this.loading = true;
			} )()

			const { data } = await axios( {
				method: 'post',
				url: '/api/v1/tender/list',
				data: {
					page: this.page,
					orderBy: this.orderBy,
					order: this.order,
					pageSize: this.rows,
					filters: this.filters,
				}
			} );

			if( data.success ) {
				action( () => {
					this.tenders = _.map( data.tenders, d => new Tender( d ) );
					this.count = data.count;
					this.loaded = true;
				} )()
			} else {
				throw new Error( "Invalid response" );
			}
		} catch( err ) {
			console.error( err );
			this.context.showSnackbar( "Can not load tenders", "error" );
		} finally {
			action( () => {
				this.loading = false;
			} )()
		}
	}
}

export default TendersList;

import {action, makeObservable, observable} from "mobx";
import {BaseModel} from "../utils/base_model";
import User from "./user";
import Tender from "./tender";
import axios from "axios";


export enum CalculationState {
	archived = "archived",
	active = "active",
}

export type CalculationResult = {
	totalPrice: number,
	installationCost: number,
	partial: {
		visibleSupportPrice: number,
		hiddenSupportPrice: number,
		transmissionPrice: number,
		awsPrice: number,
		oneTimeLicenceForInvestment: number,

		visibleSupportCost: number,
		hiddenSupportCost: number,
		totalTransmissionCost: number,
		awsCost: number,
	}
}

export default class Calculation extends BaseModel {
	id?: number;
	name?: string;
	state?: string;
	price?: number;
	user?: User;
	tender?: Tender;
	params?: any;
	priceDetails?: CalculationResult;
	createdAt?: Date;
	creationDate?: Date;

	constructor( json: any ) {
		super();

		makeObservable(this, {
			id: observable,
			name: observable,
			state: observable,
			price: observable,
			user: observable,
			tender: observable,
			params: observable,
			priceDetails: observable,
			createdAt: observable,
			creationDate: observable,
		});

		this.deserialize( json );
	}

	deserialize = action( ( json: any ) => {
		this.deserializeFields( json, [ 'id', 'name', 'price', 'createdAt', 'params', 'priceDetails', 'state', 'accessLevel' ] );

		if( json.createdAt ) {
			this.createdAt = new Date( json.createdAt );
		}

		if( json.creationDate ) {
			this.creationDate = new Date( json.creationDate );
		}

		if( json.user ) {
			this.user = new User( json.user );
		}

		if( json.tender ) {
			this.tender = new Tender( json.tender );
		}
	} )

	public async changeState() {
		const newState = this.state === CalculationState.archived ? CalculationState.active : CalculationState.archived;
		await axios( {
			method: 'patch',
			url: '/api/v1/calculate/state',
			data: {
				id: this.id,
				state: newState,
			}
		} );

		this.state = newState;
	};
}

import * as React from 'react';
import {FunctionComponent, SyntheticEvent} from 'react';
import {FormDialogProps} from "../form_dialog_wrapper";
import {observer} from "mobx-react";
import {useForm} from "react-hook-form";
import {Box, Button, Grid, TextField} from "@material-ui/core";
import {Spacer} from "../spacer";
import {passwordRegex} from "../../utils/regexes";


export const ChangePasswordForm: FunctionComponent<FormDialogProps> = observer( ( props: FormDialogProps ) => {
	const { register, handleSubmit, watch } = useForm();

	const onSubmit = async ( e: SyntheticEvent ) => {
		await handleSubmit(async ( data: any ) => {
			await props.onSubmit( data.password, data.currentPassword );
			e.preventDefault();
		})(e);
	};

	return (
		<form onSubmit={onSubmit}>
			<Grid container={true}>
				<Grid item={true} xs={12}>
					<TextField
						inputRef={register()}
						variant="outlined"
						type="password"
						name="password"
						placeholder="New password"
						label="New password"
						autoFocus={true}
						fullWidth={true}
						required={true}
						inputProps={{minLength: 8, maxLength: 30, pattern: passwordRegex.source}}
					/>

					<Spacer height={16} />

					<TextField
						inputRef={register({
							validate: (value) => value === watch('password')
						})}
						variant="outlined"
						type="password"
						name="confirmedPassword"
						placeholder="Confirm new password"
						label="Confirm new password"
						fullWidth={true}
						required={true}
						inputProps={{minLength: 8, maxLength: 30, pattern: passwordRegex.source}}
					/>

					<Spacer height={16} />

					<TextField
						inputRef={register()}
						variant="outlined"
						type="password"
						name="currentPassword"
						placeholder="Current password"
						label="Current password"
						fullWidth={true}
						required={true}
						inputProps={{minLength: 8, maxLength: 30, pattern: passwordRegex.source}}
					/>
				</Grid>

				<Spacer height={16} />

				<Grid item={true} xs={12} component={Box} display="flex" justifyContent={"flex-end"}>
					<Button onClick={props.onCancel} disabled={props.processing} variant="contained">
						Cancel
					</Button>

					<Spacer width={16} />

					<Button
						type="submit"
						color="secondary"
						variant="contained"
						disabled={props.processing}
					>
						Change password
					</Button>
				</Grid>

				<Spacer height={16} />
			</Grid>
		</form>
	)
} );

export default ChangePasswordForm;

import React, {FunctionComponent, useContext, useState} from "react";
import {observer} from "mobx-react";
import Calculation, {CalculationState} from "../models/calculation";
import {action, observable} from "mobx";
import {SnackbarsContext} from "./contexts/snackbar_ctx";
import {FormControlLabel, Switch} from "@material-ui/core";

export type Props = {
	calc: Calculation;
}

export const ChangeCalcState: FunctionComponent<Props> = observer( ( props: Props ) => {
	const notifications = useContext(SnackbarsContext);
	const [store] = useState(() =>
		observable( {
			processing: false,
		} )
	);

	const onChange = async () => {
		action( () => {
			store.processing = true;
		} )()
		try {
			await props.calc.changeState()
		} catch (e) {
			if( e.response?.data?.status === -4 ) {
				notifications.showSnackbar( e.response?.data?.message, "warning" );
			} else {
				notifications.showSnackbar( "Unable change state, try again later", "error" );
			}
		} finally {
			action( () => {
				store.processing = false;
			} )()
		}
	};

	return <>
		<FormControlLabel
			control={
				<Switch
					checked={props.calc.state === CalculationState.active}
					onChange={onChange}
					color="primary"
				/>
			}
			label="Active"
		/>
	</>
} );

import * as React from 'react';
import {FunctionComponent, SyntheticEvent} from 'react';
import {Button, TextField} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import {FormDialogProps} from "../form_dialog_wrapper";
import {observer} from "mobx-react";
import {useForm} from "react-hook-form";
import {Spacer} from "../spacer";


export const passwordRegex = /^(?=.*[a-z])(?=.*\d)[a-zA-Z\d$@!%*#?&]{8,30}$/;
export const passwordRegexDescription = '8-30 characters, at least 1 alpha and 1 digit';


// const formFields = { password: {} };
// formFields.password[ passwordRegexDescription ] = ( pwd: string = '' ) => pwd.match( passwordRegex );

/**
 * Password form
 * Should be use with form dialog wrapper
 */
export const EnterPasswordForm: FunctionComponent<FormDialogProps> = observer( ( props: FormDialogProps ) => {
	const { register, handleSubmit } = useForm();

	const onSubmit = async ( e: SyntheticEvent ) => {
		await handleSubmit(async ( data: any ) => {
			await props.onSubmit( data.password );
			e.preventDefault();
		})(e);
	};

	return (
		<form onSubmit={onSubmit}>
			<Grid container={true}>
				<Grid item={true} xs={12}>
					<TextField
						inputRef={register()}
						variant="outlined"
						type="password"
						name="password"
						placeholder="password"
						label="password"
						autoFocus={true}
						fullWidth={true}
						required={true}
					/>
				</Grid>

				<Spacer height={16} />

				<Grid item={true} xs={12} style={{ textAlign: 'right' }}>
					<Button onClick={props.onCancel} disabled={props.processing}>
						Cancel
					</Button>
					<Button
						type="submit"
						color="secondary"
						disabled={props.processing}
					>
						Ok
					</Button>
				</Grid>
			</Grid>
		</form>
	)
} );

export default EnterPasswordForm;
import React, {FunctionComponent} from "react";
import {observer} from "mobx-react";
import {Card,} from "@material-ui/core";
import styled from "styled-components";
import {theme} from "../index";
import Typography from "@material-ui/core/Typography";
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';

export const StyledRadioCardElem: FunctionComponent<any> = props => (
	<Card {...props}>{props.children} </Card>
);

export const StyledRadioCard = styled( StyledRadioCardElem )`
	padding: 16px;
	display: flex;
	flex-direction: column;
	justify-items: center;
	align-items: center;
	border: 2px solid white;
	cursor: pointer;
`;

export const RadioCard: FunctionComponent<any> = observer( ( props: any ) => {
	const onChange = ( e: any ) => {
		props.setValue( props.name, props.value );
	}
	const checked = props.watch(props.name) === props.value;

	const veryLight = `rgba(${theme.palette.secondary.light.substr(4).slice(0, -1)}, 0.2)`;

	return <StyledRadioCard onClick={onChange} style={checked ? {
		border: `2px solid ${theme.palette.secondary.main}`,
		background: veryLight,
		minHeight: props.minHeight,
	} : { minHeight: props.minHeight }}>
		{
			checked ? <CheckCircleOutlineIcon color="secondary" fontSize="large" /> : <RadioButtonUncheckedIcon color="disabled" fontSize="large" />
		}

		<Typography variant="h6" gutterBottom={true} style={{marginTop: 8}}>
			{props.title}
		</Typography>

		<Typography variant="caption" color="textSecondary">
			{props.description}
		</Typography>

		<input type="radio" name={props.name} value={props.value} ref={props.register} style={{height: 1, width: 1, opacity: 0, transform: `scale(0.1)`}} />
	</StyledRadioCard>
} );

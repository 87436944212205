import * as React from 'react';
import {FunctionComponent, SyntheticEvent, useContext, useEffect, useState} from 'react';

import {Box, Button, Link, TextField} from '@material-ui/core';
import {Link as A} from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import {observer} from "mobx-react";
import {useForm} from "react-hook-form";
import {action, observable} from "mobx";
import qrcode from 'qrcode';
import {authenticator} from 'otplib';
import * as _ from "lodash";
import {SnackbarsContext} from "../../containers/contexts/snackbar_ctx";

import pdf from "../../static/2fa_instruction.pdf";


type Props = {
	token: string;
	username: string;
}

export const CreateOtpForm: FunctionComponent<Props> = observer( ( props: Props ) => {
	const notifications = useContext(SnackbarsContext)
	const { register, watch, errors } = useForm( {
		mode: 'onChange',
	} );

	const [store] = useState(() =>
		observable( {
			otp: null as any,
			secret: null as any,
		} )
	);

	useEffect( () => {
		if( props.username ) {
			( async () => {
				const secret = authenticator.generateSecret( 10 );
				const otpauth = authenticator.keyuri(props.username, 'Biot Tender Calculator', secret);
				const qr = await qrcode.toDataURL(otpauth);

				action( () => {
					store.secret = secret;
					store.otp = qr;
				} )()

				// console.log("store.otp", store.otp);
			} )().then()
		}

		// eslint-disable-next-line
	}, [props.username] )

	const onSubmit = async ( e: SyntheticEvent ) => {
		if( _.keys(errors).length > 0 ) {
			e.preventDefault();
			notifications.showSnackbar( errors[_.keys(errors)[0]].message, "warning" );
		} else {
			return true;
		}
	};

	const checkCode = ( token: string ) => {
		return authenticator.verify( {
			token,
			secret: store.secret,
		} )
	}

	return (
		<div className="LandingPanelForm">
			<form onSubmit={onSubmit} method="post" action="/api/v1/auth/change-otp-secret">
				<input type="hidden" name="token" value={props.token || ""} />
				<input type="hidden" name="otp_secret" value={store.secret || ""} />

				<div className="LandingPanelQR">
					<img src={store.otp} alt="QR" />
				</div>

				<div className="LandingPanelInput">
					<TextField
						inputRef={register({
							validate: t => checkCode(t) || "Invalid authentication app code",
						})}
						name="otp_code"
						type="text"
						placeholder="Code from your authentication app"
						label="Code from your authentication app"
						variant="outlined"
						fullWidth={true}
						required={true}
						inputProps={{minLength: 6, maxLength: 6}}
					/>
				</div>

				<div className="LandingPanelInput">
					<Button type="submit" variant="contained" color="secondary" fullWidth={true}>
						Save
					</Button>
				</div>

				<Box>
					<Typography variant="body2">
						<Link component={A} to="/">
							Back to login page
						</Link>

						&nbsp;|&nbsp;

						<Link component="a" href={pdf} target="_blank">
							Get authentication app
						</Link>
					</Typography>
				</Box>
			</form>
		</div>
	);
} );

export default CreateOtpForm;
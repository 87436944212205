import * as _ from 'lodash';
import * as React from 'react';
import {Component} from 'react';
import {observer} from 'mobx-react';
import {action, computed, makeObservable, observable} from "mobx";
import axios from 'axios';


import UsersIcon from '@material-ui/icons/SupervisorAccount';
import AddUserIcon from '@material-ui/icons/PersonAdd';
import User from "../../models/user";
import {BaseScreen} from "../../components/base_screen";
import UsersTable from "../../components/users_table";
import {FormDialogWrapper} from "../../components/form_dialog_wrapper";
import CreateUserForm from "../../components/forms/create_user_form";
import {SnackbarsContext} from "../contexts/snackbar_ctx";
import {Order} from "./calculations";


@observer
class Users extends Component<any, any> {
	static contextType = SnackbarsContext; // To use multiple context use func component

	users: User[] = [];
	loading: boolean = false;
	loaded: boolean = true;
	processing: boolean = false;
	orderBy: string = 'id';
	order: Order = 'asc';
	private createUserDialogRef: FormDialogWrapper | null = null

	constructor( props: any, context: any ) {
		super( props, context );

		makeObservable(this, {
			users: observable,
			loading: observable,
			loaded: observable,
			processing: observable,
			orderBy: observable,
			order: observable,
			sortedList: computed,
		});
	}

	public async UNSAFE_componentWillMount() {
		await this.loadUsers();
	}

	public get sortedList() {
		const l = _.sortBy( this.users || [], ( row: any ) => {
			return row[this.orderBy];
		} )

		return this.order === 'asc' ? l : _.reverse(l);
	}

	public render() {
		return (
			<BaseScreen
				icon={UsersIcon}
				title="Users"
				actionIcon={AddUserIcon}
				onAction={this.showAddUserDialog}
				loading={this.loading}
				loaded={this.loaded}
				emptyListMessage={this.loaded && !this.users.length ? 'Users list is empty' : undefined}
			>
				{this.sortedList.length && <UsersTable users={this.sortedList} onDelete={this.onDelete} orderBy={this.orderBy} order={this.order} changeOrder={this.changeOrder} />}

				<FormDialogWrapper
					ref={this.setCreateUserDialogRef}
					name="Create new user"
					form={CreateUserForm}
					onSubmit={this.createUser}
					onCancel={this.hideAddUserDialog}
					processing={this.processing}
				/>

			</BaseScreen>
		);
	}

	private async loadUsers() {
		try {
			action( () => {
				this.loading = true;
			} )()

			const resp = await axios( {
				method: 'get',
				url: '/api/v1/users',
			} );

			if( resp.data.success ) {
				action( () => {
					this.users = _.map( resp.data.users, userData => new User( userData ) );
					this.loaded = true;
				} )()
			} else {
				throw new Error( "Invalid response" );
			}
		} catch( err ) {
			console.error( err );
			this.context.showSnackbar( "Can not load users" );
		} finally {
			action( () => {
				this.loading = false;
			} )()
		}
	}

	private readonly setCreateUserDialogRef = ( ref: any ) => {
		this.createUserDialogRef = ref;
	}

	private readonly showAddUserDialog = async () => {
		await this.createUserDialogRef?.show();
	}

	private readonly hideAddUserDialog = async () => {
		await this.createUserDialogRef?.hide( null );
	}

	private readonly createUser = async ( username: string, email: string ) => {
		if( !this.processing ) {
			try {
				this.processing = true;

				const resp = await axios( {
					method: 'post',
					url: '/api/v1/users',
					data: {
						username,
						email,
					},
				} );

				if( resp.data.success ) {
					const createdUser = new User( resp.data.user );
					this.users.push( createdUser );
					this.context.showSnackbar( "User has been created successfully" );
				} else {
					throw new Error( "Invalid response" );
				}
			} catch( err ) {
				console.error( err );
				this.context.showSnackbar( "Can not create user", "error" );
			} finally {
				this.processing = false;
			}
		}
	}

	private changeOrder = (cellId: any) => {
		if( this.orderBy === cellId ) {
			this.order = this.order === 'asc' ? 'desc' : 'asc';
		} else {
			this.orderBy = cellId;
			this.order = 'asc'
		}
	};

	private readonly onDelete = action( async ( userId: number ) => {
		_.remove( this.users, { id: userId } );
	} );
}

export default Users;

import * as _ from 'lodash';
import * as React from 'react';
import {Component} from 'react';
import {observer} from 'mobx-react';
import {Dialog, DialogContent, DialogTitle, LinearProgress,} from '@material-ui/core';

import {action, makeObservable, observable} from "mobx";


export interface FormDialogWrapperProps {
	name: string;
	form: any;
	onSubmit: ( ...x: any[] ) => Promise<void>;
	onCancel: ( ...x: any[] ) => Promise<void>;
	processing: boolean | number,
	formValues?: any;
	formData?: any;
	dialogMaxWidth?: 'xs' | 'sm' | 'md';
	dialogFullWidth?: boolean;
}

export interface FormDialogProps {
	processing: boolean,
	onSubmit: ( ...x: any[] ) => Promise<void>;
	onCancel: ( ...x: any[] ) => Promise<void>;
	value: any;
	data: any;

	// react-inform injected props
	fields: any;
	form: any;
}

/**
 * Form dialog wrapper component
 * Wraps react-inform form into material ui dialog
 */
@observer
export class FormDialogWrapper extends Component<FormDialogWrapperProps, {}> {
	public open = false;
	public values: any = {};
	public data: any = {};

	constructor( props: any, context: any ) {
		super( props, context );

		makeObservable(this, {
			open: observable,
			values: observable,
			data: observable
		});
	}

	/**
	 * Shows form dialog
	 * @returns {void} Promise which resolves when action is finished
	 */
	public show = action( () => {
		this.values = this.props.formValues;
		this.data = this.props.formData;
		this.open = true;
	} );

	/**
	 * Hides form dialog
	 * @param {any} event an event
	 * @param {boolean} force when true this function closes dialog even if form is processing
	 * @returns {void} Promise which resolves when action is finished
	 */
	public hide: any = action( ( event: any, force?: any ) => {
		if( !this.props.processing || force ) {
			this.open = false;
		}

	} );

	public UNSAFE_componentWillMount() {
		action( () => {
			this.values = this.props.formValues;
		} )()
	}

	public UNSAFE_componentWillReceiveProps( nextProps: any ) {
		action( () => {
			this.values = nextProps.formValues;
			this.data = nextProps.formData;
		} )()
	}

	public render() {
		const Form = this.props.form;
		return (
			<Dialog open={this.open} onClose={this.hide} maxWidth={this.props.dialogMaxWidth} fullWidth={this.props.dialogFullWidth}>
				<div style={{ opacity: this.props.processing ? 1 : 0 }}>
					{
						_.isBoolean( this.props.processing ) ?
							<LinearProgress/>
							:
							<LinearProgress variant="determinate" value={this.props.processing}/>
					}
				</div>
				<DialogTitle>{this.props.name}</DialogTitle>
				<DialogContent>
					<Form
						processing={!!this.props.processing}
						onSubmit={this.onSubmit}
						onCancel={this.props.onCancel}
						value={this.values}
						data={this.data}
						onChange={this.onChange}
					/>
				</DialogContent>
			</Dialog>
		)
	}

	private onChange = action( ( values: any ) => {
		this.values = values;
	} );

	private onSubmit = async ( ...args: any[] ) => {
		await this.props.onSubmit( ...args );
		action( () => {
			this.open = false;
		} )()
	};
}

import * as React from 'react';
import {Component} from 'react';
import {observer} from 'mobx-react';
import {NavLink} from 'react-router-dom';

import './breadcrumb.css';

export interface BreadCrumb {
	link: string;
	title: string;
}


export interface ViewBreadcrumbProps {
	items: BreadCrumb[];
}

@observer
export class ViewBreadcrumb extends Component<ViewBreadcrumbProps, {}> {

	render() {

		return (
			<div className="ViewBreadcrumb">
				{this.props.items.map( bc => <NavLink activeClassName="ActiveBreadCrumb" key={bc.link} to={bc.link}>{bc.title}</NavLink> )}
			</div>
		);
	}
}
import * as React from 'react';
import {FunctionComponent, useContext, useEffect, useState} from 'react';
import {observer} from 'mobx-react';
import {useHistory, useParams} from "react-router-dom";


import TenderIcon from '@material-ui/icons/Gavel';
import {BaseScreen} from "../../components/base_screen";
import axios from "axios";
import {action, observable} from "mobx";
import TenderForm from "../../components/forms/tender_form";
import {SnackbarsContext} from "../contexts/snackbar_ctx";
import Tender from "../../models/tender";
import CalcIcon from "@material-ui/icons/Tune";


const TenderDetails: FunctionComponent<{}> = observer( () => {
	const notifications = useContext(SnackbarsContext);
	const { id } = useParams() as any;
	const history = useHistory();
	const [store] = useState(() =>
		observable( {
			loading: false,
			loaded: false,
			processing: false,
			tender: undefined as undefined | Tender,
		} )
	);

	useEffect( () => {
		if( id ) {
			( async () => {
				action( () => {
					store.loading = true;
				} )()
				try {
					const { data } = await axios( {
						method: 'post',
						url: '/api/v1/tender/details',
						data: {
							id,
						}
					} );

					action( () => {
						store.tender = new Tender( data.details );
						store.loaded = true;
					} )()
				} catch (e) {
					notifications.showSnackbar( "Unable to load tender details", "error" );
				} finally {
					action( () => {
						store.loading = false;
					} )()
				}
			})().then();
		}
	}, [id] )

	const onSubmit = async ( params: any ) => {
		action( () => {
			store.processing = true;
		} )()
		try {
			await axios( {
				method: 'patch',
				url: '/api/v1/tender',
				data: {
					id,
					...params,
				}
			} );

			notifications.showSnackbar( "Changes saved", "success" );
		} catch (e) {
			notifications.showSnackbar( "Unable to save, try again later", "error" );
		} finally {
			action( () => {
				store.processing = false;
			} )()
		}
	};

	const showCalculations = () => {
		history.push(`/tenders/${id}/calculations`);
	}

	return (
		<BaseScreen
			icon={TenderIcon}
			title={store.tender?.name}
			loading={store.loading}
			loaded={store.loaded}
			actionIcon={CalcIcon}
			onAction={showCalculations}
			breadCrumbs={[
				{
					link: "/",
					title: "Tenders",
				},
				{
					link: window.location.pathname,
					title: store.tender?.name || "",
				},
			]}
		>
			{
				store.tender && (
					<TenderForm
						processing={store.processing}
						tender={store.tender}
						onSubmit={onSubmit}
					/>
				)
			}
		</BaseScreen>
	);
} );

export default TenderDetails;

import * as React from 'react';
import {FunctionComponent, useContext} from 'react';
import {observer} from 'mobx-react';
import {List, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText} from '@material-ui/core';
import User from "../models/user";
import moment from 'moment';
import styled from 'styled-components';

import UsernameIcon from '@material-ui/icons/Face';
import EmailIcon from '@material-ui/icons/MailOutline';
import PasswordIcon from '@material-ui/icons/Lock';
import LastLoginIcon from '@material-ui/icons/Today';
import ChangeUsername from "../containers/change_username";
import {SnackbarsContext} from "../containers/contexts/snackbar_ctx";
import ChangeEmail from "../containers/change_email";
import ChangePassword from "../containers/change_password";


const AccountDetailsContainer = styled.div`
	width: 100%;
`;

type Props = {
	user: User;
}



export const AccountDetails: FunctionComponent<Props> = observer( ( props: Props ) => {
	const notifications = useContext(SnackbarsContext);

	const passwordDescription = props.user.passwordUpdatedAt ?
		`Password was changed ${moment(props.user.passwordUpdatedAt).fromNow()}` :
		'Password never changed';

	return (
		<AccountDetailsContainer>
			<List>
				<ListItem>
					<ListItemIcon>
						<UsernameIcon />
					</ListItemIcon>
					<ListItemText primary="Username" secondary={props.user.username}/>
					<ListItemSecondaryAction>
						<ChangeUsername notifications={notifications} />
					</ListItemSecondaryAction>
				</ListItem>
				<ListItem>
					<ListItemIcon>
						<EmailIcon />
					</ListItemIcon>
					<ListItemText primary="Email" secondary={props.user.email}/>
					<ListItemSecondaryAction>
						<ChangeEmail notifications={notifications} />
					</ListItemSecondaryAction>
				</ListItem>
				<ListItem>
					<ListItemIcon>
						<PasswordIcon />
					</ListItemIcon>
					<ListItemText primary="Password" secondary={passwordDescription}/>
					<ListItemSecondaryAction>
						<ChangePassword notifications={notifications} />
					</ListItemSecondaryAction>
				</ListItem>
				<ListItem>
					<ListItemIcon>
						<LastLoginIcon />
					</ListItemIcon>
					<ListItemText primary="Last login" secondary={moment(props.user.lastLogin).fromNow()} />
				</ListItem>
			</List>

		</AccountDetailsContainer>
	)
} );

export default AccountDetails;
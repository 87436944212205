import * as React from 'react';
import {FunctionComponent, SyntheticEvent} from 'react';
import {FormDialogProps} from "../form_dialog_wrapper";
import {observer} from "mobx-react";
import {useForm} from "react-hook-form";
import {Box, Button, Grid, TextField} from "@material-ui/core";
import {Spacer} from "../spacer";


export const ChangeUsernameForm: FunctionComponent<FormDialogProps> = observer( ( props: FormDialogProps ) => {
	const { register, handleSubmit } = useForm( {
		defaultValues: {
			username: props.value?.username,
		},
	} );

	const onSubmit = async ( e: SyntheticEvent ) => {
		await handleSubmit(async ( data: any ) => {
			await props.onSubmit( data.username );
			e.preventDefault();
		})(e);
	};

	return (
		<form onSubmit={onSubmit}>
			<Grid container={true}>
				<Grid item={true} xs={12}>
					<TextField
						inputRef={register()}
						variant="outlined"
						type="text"
						name="username"
						placeholder="New username"
						label="New username"
						autoFocus={true}
						fullWidth={true}
						required={true}
						inputProps={{minLength: 3, maxLength: 30}}
					/>
				</Grid>

				<Spacer height={16} />

				<Grid item={true} xs={12} component={Box} display="flex" justifyContent={"flex-end"}>
					<Button onClick={props.onCancel} disabled={props.processing} variant="contained">
						Cancel
					</Button>

					<Spacer width={16} />

					<Button
						type="submit"
						color="secondary"
						variant="contained"
						disabled={props.processing}
					>
						Change username
					</Button>
				</Grid>

				<Spacer height={16} />
			</Grid>
		</form>
	)
} );

export default ChangeUsernameForm;

import * as React from 'react';
import {FunctionComponent, SyntheticEvent, useContext} from 'react';
import {observer} from 'mobx-react';
import {
	Button,
	Checkbox,
	createStyles,
	FormControl,
	FormControlLabel,
	InputAdornment,
	InputLabel,
	makeStyles,
	MenuItem,
	Paper,
	Select,
	TextField,
	Theme,
	Typography
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import {Controller, useForm} from "react-hook-form";
import * as _ from "lodash";
import Calculation from "../../models/calculation";
import {Spacer} from "../spacer";
import {CalcInputCount} from "./calc_form_count_input";
import Tender from "../../models/tender";
import {countries} from "countries-list";
import {UserContext} from "../../containers/contexts/user_ctx";
import CalcResultsGrid from "../calc_results_grid";
import {KeyboardDatePicker} from "@material-ui/pickers";
import {TextInput} from "./form_text_input";
import {Link as A} from 'react-router-dom';
import {ChangeCalcState} from "../../containers/change_calc_state";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		paper: {
			padding: 16,
		},
	}),
);


interface CalcFormProps {
	onSubmit?: ( name: string, ...x: any[] ) => Promise<void>;
	plnToEur?: number;
	processing: boolean;
	result?: number;
	currentCalculation?: Calculation;
	resultCurrency?: string;
	calc?: Calculation;
	tender?: Tender;
	readonly: boolean;
}

export const CalcForm: FunctionComponent<CalcFormProps> = observer( ( props: CalcFormProps ) => {
	const classes = useStyles();
	const {user} = useContext(UserContext);

	const { register, handleSubmit, control, errors } = useForm( {
		defaultValues: props.calc?.params
	} );

	const onSubmit = async ( e: SyntheticEvent ) => {
		await handleSubmit(async ( data: any ) => {
			const threadNodesQuantity =
				// data.devices_ebloc_nema_lte +
				data.devices_ebloc_nema_thread +
				data.devices_ebloc_nema_thread_gnss +
				// data.devices_ebloc_zhaga_lte +
				data.devices_ebloc_zhaga_thread +
				data.devices_ebloc_zhaga_thread_gnss +
				data.devices_iblock;

			const hubiotsQuantity =
				data.devices_hub1 +
				data.devices_hub2;

			if( Math.ceil( threadNodesQuantity / hubiotsQuantity ) > 70 ) {
				if ( !window.confirm("Are you sure you want to provide less then 1 HUBiot for 70 thread nodes") ) {
					return;
				}
			}

			await props.onSubmit?.( data.name, _.omit(data, 'name') );
		})(e);
	};

	const admin_params = (window as any).config?.admin_params;

	return (
		<form onSubmit={onSubmit}>
			<Spacer height={16} />

			<Grid container={true} spacing={2}>
				<Grid item={true} xs={12} md={3}>
					<Typography variant="h6" align={"right"}>
						Base information
					</Typography>

					<Typography variant="body1" color="textSecondary" align={"right"}>
						Provide base information
					</Typography>
				</Grid>
				<Grid item={true} xs={12} md={9}>
					<Paper className={classes.paper} elevation={2}>
						<Grid container={true} spacing={2}>
							<Grid item={true} xs={12} md={6}>
								<TextField
									inputRef={register()}
									variant="outlined"
									name="name"
									type="text"
									placeholder="Calculation name"
									label="Calculation name"
									fullWidth={true}
									disabled={props.readonly}
									defaultValue={props.calc?.name || ""}
									required={true}
									inputProps={{minLength: 5, maxLength: 64}}
								/>
							</Grid>
							<Grid item={true} xs={12} md={6}>
								<Controller
									render={(prps) => (
										<KeyboardDatePicker
											inputVariant="outlined"
											fullWidth
											openTo="year"
											label={`Creation date`}
											views={["year", "month", "date"]}
											format="YYYY-MM-DD"
											// maxDate={moment().subtract(1, 'years').toDate()}
											// initialFocusedDate={moment().subtract(1, 'years').toDate()}
											TextFieldComponent={ ( prps: any ) => <TextField
												required
												{...prps}
												disabled={!user.isAdmin || props.readonly}
											/>}
											inputRef={prps.ref}
											disabled={!user.isAdmin || props.readonly}
											{...(_.omit(prps, 'ref'))}
										/>
									)}
									name="creation_date"
									rules={{ required: true }}
									control={control}
									defaultValue={props.calc?.creationDate ? new Date(props.calc?.creationDate ) : new Date()}
								/>
							</Grid>
							<Grid item={true} xs={12} md={6}>
								<FormControl variant="outlined" fullWidth={true} error={Boolean(errors.output_currency)}>
									<InputLabel id="output-currency-label" error={Boolean(errors.output_currency)}>{`Output currency *`}</InputLabel>
									<Controller
										as={
											<Select
												labelId="output-currency-label"
												id="output-currency-select-outlined"
												label={`Output currency`}
												fullWidth={true}
											>
												<MenuItem value="">-</MenuItem>
												<MenuItem value="PLN">{`PLN`}</MenuItem>
												<MenuItem value="EUR">{`EUR`}</MenuItem>
											</Select>
										}
										name="output_currency"
										rules={{ required: true }}
										control={control}
										defaultValue={props.calc?.params?.output_currency || ""}
										disabled={props.readonly}
									/>
								</FormControl>
							</Grid>
							<Grid item={true} xs={12} md={6}>
								{
									user.isAdmin ? (
										<TextField
											inputRef={register({valueAsNumber: true})}
											name="eur_to_pln"
											variant="outlined"
											type="text"
											placeholder="EUR exchange rate to PLN"
											label="EUR exchange rate to PLN"
											fullWidth={true}
											disabled={!user.isAdmin || props.readonly}
											defaultValue={props.calc?.params?.eur_to_pln || props.plnToEur}
										/>
									) : <>
										<TextField
											inputRef={register({valueAsNumber: true})}
											name="eur_to_pln"
											variant="outlined"
											type="text"
											placeholder="EUR exchange rate to PLN"
											label="EUR exchange rate to PLN"
											fullWidth={true}
											disabled={!user.isAdmin || props.readonly}
											value={props.plnToEur || props.calc?.params?.eur_to_pln}
											defaultValue={props.calc?.params?.eur_to_pln || props.plnToEur}
										/>
										<input type="hidden" ref={register({valueAsNumber: true})} name="eur_to_pln" value={props.plnToEur} />
									</>
								}
							</Grid>
							<Grid item={true} xs={12} md={6}>
								<CalcInputCount
									register={register}
									readonly={props.readonly}
									name="licence_period_in_months"
									label="Licence period"
									InputProps={{
										endAdornment: <InputAdornment position="start">months</InputAdornment>,
									}}
									className={"no-inpt-arrows"}
								/>
							</Grid>
							<Grid item={true} xs={12} md={6}>
								<TextField
									variant="outlined"
									type="text"
									placeholder="Installation country"
									label="Installation country"
									fullWidth={true}
									disabled={true}
									defaultValue={ props.tender?.locationCountry ? countries[props.calc?.params?.installation_country || props.tender?.locationCountry].name : ""}
									required={true}
									inputProps={{minLength: 5, maxLength: 64}}
								/>
								<input type="hidden" ref={register()} name="installation_country" value={props.tender?.locationCountry || ""} />
							</Grid>
							<Grid item={true} xs={12} md={6}>
								<CalcInputCount
									register={register}
									readonly={props.readonly}
									name="baby_sitting_time_in_hours"
									label="Baby sitting time"
									InputProps={{
										endAdornment: <InputAdornment position="start">hours</InputAdornment>,
									}}
									className={"no-inpt-arrows"}
								/>
							</Grid>
							<Grid item={true} xs={12} md={6}>
								<CalcInputCount
									register={register}
									readonly={props.readonly}
									name="training_time_in_days"
									label="Training time"
									InputProps={{
										endAdornment: <InputAdornment position="start">days</InputAdornment>,
									}}
									className={"no-inpt-arrows"}
								/>
							</Grid>
						</Grid>
					</Paper>
				</Grid>
			</Grid>

			{
				user.isAdmin && <>
					<Spacer height={32} />

					<Grid container={true} spacing={2}>
						<Grid item={true} xs={12} md={3}>
							<Typography variant="h6" align={"right"}>
								Margins
							</Typography>

							<Typography variant="body1" color="textSecondary" align={"right"}>
								Provide margins or leave default
							</Typography>
						</Grid>
						<Grid item={true} xs={12} md={9}>
							<Paper className={classes.paper} elevation={2}>
								<Grid container={true} spacing={2}>
									<Grid item={true} xs={12} md={3}>
										<CalcInputCount
											register={register}
                                            readonly={props.readonly}
											name="margin"
											label="Margin"
											defaultValue={props.calc?.params?.margin || admin_params.margin}
											max={99}
											step={1}
											InputProps={{
												endAdornment: <InputAdornment position="start">%</InputAdornment>,
											}}
											className={"no-inpt-arrows"}
										/>
									</Grid>
									<Grid item={true} xs={12} md={3}>
										<CalcInputCount
											register={register}
                                            readonly={props.readonly}
											name="margin_support"
											label="Margin support"
											defaultValue={props.calc?.params?.margin_support || admin_params.margin_support}
											max={99}
											step={1}
											InputProps={{
												endAdornment: <InputAdornment position="start">%</InputAdornment>,
											}}
											className={"no-inpt-arrows"}
										/>
									</Grid>
									<Grid item={true} xs={12} md={3}>
										<CalcInputCount
											register={register}
                                            readonly={props.readonly}
											name="margin_transmission"
											label="Margin transmission"
											defaultValue={props.calc?.params?.margin_transmission || admin_params.margin_transmission}
											max={99}
											step={1}
											InputProps={{
												endAdornment: <InputAdornment position="start">%</InputAdornment>,
											}}
											className={"no-inpt-arrows"}
										/>
									</Grid>
									<Grid item={true} xs={12} md={3}>
										<CalcInputCount
											register={register}
                                            readonly={props.readonly}
											name="margin_aws"
											label="Margin aws"
											defaultValue={props.calc?.params?.margin_aws || admin_params.margin_aws}
											max={99}
											step={1}
											InputProps={{
												endAdornment: <InputAdornment position="start">%</InputAdornment>,
											}}
											className={"no-inpt-arrows"}
										/>
									</Grid>
								</Grid>
							</Paper>
						</Grid>
					</Grid>
				</>
			}

			<Spacer height={32}/>

			<Grid container={true} spacing={2}>
				<Grid item={true} xs={12} md={3}>
					<Typography variant="h6" align={"right"}>
						Transmission cost
					</Typography>

					<Typography variant="body1" color="textSecondary" align={"right"}>
						Should transmission cost be added
					</Typography>
				</Grid>
				<Grid item={true} xs={12} md={9}>
					<Paper className={classes.paper} elevation={2}>
						<Grid container={true} spacing={2}>
							<Grid item={true} xs={12} md={12}>
								<FormControlLabel
									control={
										<Controller
											control={control}
											name="add_transmission_cost"
											render={(
												{ onChange, onBlur, value, name, ref },
												{ invalid, isTouched, isDirty }
											) => (
												<Checkbox
													onBlur={onBlur}
													onChange={(e) => onChange(e.target.checked)}
													checked={value}
													inputRef={ref}
													disabled={props.readonly}
												/>
											)}
											defaultValue={!_.isNil( props.calc?.params?.add_transmission_cost ) ?  props.calc?.params?.add_transmission_cost : true}
										/>
									}
									label="Add transmission cost"
									disabled={props.readonly}
								/>
							</Grid>
						</Grid>
					</Paper>
				</Grid>
			</Grid>

			{
				user.isAdmin && <>
					<Spacer height={32}/>

					<Grid container={true} spacing={2}>
						<Grid item={true} xs={12} md={3}>
							<Typography variant="h6" align={"right"}>
								Hidden support cost
							</Typography>

							<Typography variant="body1" color="textSecondary" align={"right"}>
								Should hidden support cost be added
							</Typography>
						</Grid>
						<Grid item={true} xs={12} md={9}>
							<Paper className={classes.paper} elevation={2}>
								<Grid container={true} spacing={2}>
									<Grid item={true} xs={12} md={12}>
										<FormControlLabel
											control={
												<Controller
													control={control}
													name="add_hidden_support_cost"
													render={(
														{ onChange, onBlur, value, name, ref },
														{ invalid, isTouched, isDirty }
													) => (
														<Checkbox
															onBlur={onBlur}
															onChange={(e) => onChange(e.target.checked)}
															checked={value}
															inputRef={ref}
															disabled={props.readonly}
														/>
													)}
													defaultValue={!_.isNil( props.calc?.params?.add_hidden_support_cost ) ?  props.calc?.params?.add_hidden_support_cost : true}
												/>
											}
											label="Add hidden support cost"
											disabled={props.readonly}
										/>
									</Grid>
								</Grid>
							</Paper>
						</Grid>
					</Grid>
				</>
			}

			<Spacer height={32} />

			<Grid container={true} spacing={2}>
				<Grid item={true} xs={12} md={3}>
					<Typography variant="h6" align={"right"}>
						Infrastructure
					</Typography>

					<Typography variant="body1" color="textSecondary" align={"right"}>
						Provide infrastructure information
					</Typography>
				</Grid>
				<Grid item={true} xs={12} md={9}>
					<Paper className={classes.paper} elevation={2}>
						<Grid container={true} spacing={2}>
							<Grid item={true} xs={12} md={6}>
								<FormControl variant="outlined" fullWidth={true} error={Boolean(errors.infrastructure_server_type)}>
								<InputLabel id="server-type-label" error={Boolean(errors.infrastructure_server_type)}>{`Server type`}</InputLabel>
								<Controller
									as={
										<Select
											labelId="output-currency-label"
											id="server-type-select-outlined"
											label={`Server type`}
											fullWidth={true}
										>
											<MenuItem value="cloud">{`Cloud`}</MenuItem>
											<MenuItem value="on-premise" disabled={true}>{`On premise`}</MenuItem>
										</Select>
									}
									name="infrastructure_server_type"
									rules={{ required: true }}
									control={control}
									defaultValue={props.calc?.params?.infrastructure_server_type || "cloud"}
									disabled={props.readonly}
								/>
							</FormControl>
							</Grid>
							<Grid item={true} xs={12} md={6}>
								<CalcInputCount
									register={register}
									readonly={props.readonly}
									name="infrastructure_data_frequency"
									label="Data frequency"
									disabled={true}
									defaultValue={"24"}
									InputProps={{
										endAdornment: <InputAdornment position="start">reports / day</InputAdornment>,
									}}
									className={"no-inpt-arrows"}
								/>
							</Grid>
							<Grid item={true} xs={12} md={4} style={{visibility: 'hidden', height: 0, marginTop: -20,}}>
								<CalcInputCount
									register={register}
									readonly={props.readonly}
									name="infrastructure_data_density"
									label="Data recording density"
									disabled={true}
								/>
							</Grid>

							{
								user.isAdmin && <>
									<Grid item={true} xs={12} md={12}>
										<CalcInputCount
											register={register}
											readonly={props.readonly}
											name="aws_cost_node_calculation"
											label="AWS cost - Node calculation"
											defaultValue={props.calc?.params?.aws_cost_node_calculation || admin_params.aws_cost_node_calculation}
											InputProps={{
												endAdornment: <InputAdornment position="start"></InputAdornment>,
											}}
											className={"no-inpt-arrows"}
										/>
									</Grid>
								</>
							}
						</Grid>
					</Paper>
				</Grid>
			</Grid>

			<Spacer height={32} />

			<Grid container={true} spacing={2}>
				<Grid item={true} xs={12} md={3}>
					<Typography variant="h6" align={"right"}>
						Devices count
					</Typography>

					<Typography variant="body1" color="textSecondary" align={"right"}>
						Provide devices count
					</Typography>
				</Grid>
				<Grid item={true} xs={12} md={9}>
					<Paper className={classes.paper} elevation={2}>
						<Grid container={true} spacing={2}>
							<Grid item={true} xs={12} md={4}>
								<Typography variant="caption">
									Count of thread controllers
								</Typography>

								<Spacer height={16} />

								<CalcInputCount register={register} readonly={props.readonly}
									name="devices_iblock"
									label="iBloc"
								/>

								<Spacer height={16} />

								<CalcInputCount register={register} readonly={props.readonly}
									name="devices_ebloc_nema_thread"
									label="eBloc Nema Thread"
								/>

								<Spacer height={16} />

								<CalcInputCount register={register} readonly={props.readonly}
									name="devices_ebloc_nema_thread_gnss"
									label="eBloc Nema Thread + GPS"
								/>

								<Spacer height={16} />

								<CalcInputCount register={register} readonly={props.readonly}
									name="devices_ebloc_zhaga_thread"
									label="eBloc Zhaga Thread"
								/>

								<Spacer height={16} />

								<CalcInputCount register={register} readonly={props.readonly}
									name="devices_ebloc_zhaga_thread_gnss"
									label="eBloc Zhaga Thread + GPS"
								/>
							</Grid>
							<Grid item={true} xs={12} md={4}>
								<Typography variant="caption">
									Count of LTE controllers
								</Typography>

								<Spacer height={16} />

								<CalcInputCount register={register} readonly={props.readonly}
									name="devices_ebloc_nema_lte"
									label="eBloc Nema LTE"
									disabled={true}
								/>

								<Spacer height={16} />

								<CalcInputCount register={register} readonly={props.readonly}
									name="devices_ebloc_zhaga_lte"
									label="eBloc Zhaga LTE"
									disabled={true}
								/>
							</Grid>
							<Grid item={true} xs={12} md={4}>
								<Typography variant="caption">
									Count of routers
								</Typography>

								<Spacer height={16} />

								<CalcInputCount register={register} readonly={props.readonly}
									name="devices_hub1"
									label="HUBiot 1"
								/>

								<Spacer height={16} />

								<CalcInputCount register={register} readonly={props.readonly}
									name="devices_hub2"
									label="HUBiot 2"
								/>
							</Grid>
						</Grid>
					</Paper>
				</Grid>
			</Grid>

			<Spacer height={32} />

			<Grid container={true} spacing={2}>
				<Grid item={true} xs={12} md={3}>
					<Typography variant="h6" align={"right"}>
						Lighting cabinets
					</Typography>

					<Typography variant="body1" color="textSecondary" align={"right"}>
						Provide lighting cabinets information
					</Typography>
				</Grid>
				<Grid item={true} xs={12} md={9}>
					<Paper className={classes.paper} elevation={2}>
						<Grid container={true} spacing={2}>
							<Grid item={true} xs={12} md={4}>
								<Typography variant="caption">
									Count of hardware
								</Typography>

								<Spacer height={16} />

								<CalcInputCount register={register} readonly={props.readonly}
									name="cabinets_cabinets"
									label="Cabinets"
									disabled={true}
								/>

								<Spacer height={16} />

								<CalcInputCount register={register} readonly={props.readonly}
									name="cabinets_hubiots"
									label="HUBiots"
									disabled={true}
								/>

								<Spacer height={16} />

								<CalcInputCount register={register} readonly={props.readonly}
									name="cabinets_x1"
									label="Registration of network parameters verified"
									disabled={true}
								/>

								<Spacer height={16} />

								<CalcInputCount register={register} readonly={props.readonly}
									name="cabinets_x2"
									label="Registration of network parameters"
									disabled={true}
								/>
							</Grid>
							<Grid item={true} xs={12} md={4}>
								<Typography variant="caption">
									Measurements
								</Typography>

								<Spacer height={16} />

								<CalcInputCount register={register} readonly={props.readonly}
									name="cabinets_x3"
									label="Number of measured input circuits"
									disabled={true}
								/>

								<Spacer height={16} />

								<CalcInputCount register={register} readonly={props.readonly}
									name="cabinets_x4"
									label="Number of measured output circuits"
									disabled={true}
								/>

								<Spacer height={16} />

								<Typography variant="caption">
									Control
								</Typography>

								<Spacer height={16} />

								<CalcInputCount register={register} readonly={props.readonly}
									name="cabinets_x5"
									label="Controll channels"
									disabled={true}
								/>
							</Grid>
							<Grid item={true} xs={12} md={4}>
								<Typography variant="caption">
									Cabinet control
								</Typography>

								<Spacer height={16} />

								<CalcInputCount register={register} readonly={props.readonly}
									name="cabinets_doors"
									label="Doors"
									disabled={true}
								/>

								<Spacer height={16} />

								<CalcInputCount register={register} readonly={props.readonly}
									name="cabinets_light_sensor"
									label="Light sensor"
									disabled={true}
								/>

								<Spacer height={16} />

								<CalcInputCount register={register} readonly={props.readonly}
									name="cabinets_x6"
									label="Safety state"
									disabled={true}
								/>

								<Spacer height={16} />

								<CalcInputCount register={register} readonly={props.readonly}
									name="cabinets_x7"
									label="Connectors state"
									disabled={true}
								/>
							</Grid>
						</Grid>
					</Paper>
				</Grid>
			</Grid>


			<Spacer height={32}/>

			<Grid container={true} spacing={2}>
				<Grid item={true} xs={12} md={3}>
					<Typography variant="h6" align={"right"}>
						Extra costs and discounts
					</Typography>

					<Typography variant="body1" color="textSecondary" align={"right"}>
						Provide extra costs and discounts
					</Typography>
				</Grid>
				<Grid item={true} xs={12} md={9}>
					<Paper className={classes.paper} elevation={2}>
						<Grid container={true} spacing={2}>
							<Grid item={true} xs={12} md={4}>
								<CalcInputCount
									register={register}
									readonly={props.readonly}
									name="extra_cost_value_in_pln"
									label="Extra cost value"
									InputProps={{
										endAdornment: <InputAdornment position="start">PLN</InputAdornment>,
									}}
									className={"no-inpt-arrows"}
								/>
							</Grid>
							<Grid item={true} xs={12} md={8}>
								<TextInput
									register={register}
									disabled={props.readonly}
									name="extra_cost_description"
									label="Extra cost description"
									required={false}
									defaultValue={props.calc?.params?.extra_cost_description }
									multiline={true}
									rows={4}
								/>
							</Grid>
							<Grid item={true} xs={12} md={4}>
								<CalcInputCount
									register={register}
									readonly={props.readonly}
									disabled={!user.isAdmin || props.readonly}
									name="discount_value_in_pln"
									label="Discount value"
									InputProps={{
										endAdornment: <InputAdornment position="start">PLN</InputAdornment>,
									}}
									className={"no-inpt-arrows"}
								/>
							</Grid>
							<Grid item={true} xs={12} md={8}>
								<TextInput
									register={register}
									disabled={!user.isAdmin || props.readonly}
									name="discount_description"
									label="Discount description"
									required={false}
									defaultValue={props.calc?.params?.discount_description }
									multiline={true}
									rows={4}
								/>
							</Grid>
						</Grid>
					</Paper>
				</Grid>
			</Grid>

			<Spacer height={32} />

			<Grid container={true} spacing={2}>
				<Grid item={true} xs={12} />
				<Grid item={true} xs={12} md={3} />
				<Grid item={true} xs={12} md={3}>
					{
						props.readonly ? (
							<>
								<A to={`/tenders/${props.calc?.tender?.id}/calculations/${props.calc?.id}/recreate/`}>
									<Button
										type="button"
										color="primary"
										fullWidth={true}
										variant="contained"
										size="large"
										disabled={props.processing}
										style={{height: 64}}
									>
										Use as template
									</Button>
								</A>

								{
									props.calc && (
										<div style={{margin: "24px 0"}}>
											<ChangeCalcState calc={props.calc} />
										</div>
									)
								}
							</>
						) : (
							<Button
								type="submit"
								color="primary"
								fullWidth={true}
								variant="contained"
								size="large"
								disabled={props.processing}
								style={{height: 64}}
							>
								Generate
							</Button>
						)
					}

				</Grid>

				{
					props.readonly && (
						<CalcResultsGrid calc={props.calc} currentCalculation={props.currentCalculation} result={props.result} resultCurrency={props.resultCurrency} />
					)
				}

				<Grid item={true} xs={12} />
			</Grid>
		</form>
	)
} );

export default CalcForm;

import * as React from 'react';
import {FunctionComponent, useContext} from 'react';
import {observer} from 'mobx-react';
import {
	Box,
	createStyles,
	makeStyles,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	TableSortLabel,
	Theme
} from '@material-ui/core';
import User from "../models/user";
import moment from 'moment';
import UserStatus from '../containers/user_status';
import {TableWrapper} from "./html_components";
import {SnackbarsContext} from "../containers/contexts/snackbar_ctx";
import AccessLevelChip from "../containers/access_level";
import ResetOtp from "../containers/reset_otp";
import {Order} from "../containers/screens/calculations";


const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		visuallyHidden: {
			border: 0,
			clip: 'rect(0 0 0 0)',
			height: 1,
			margin: -1,
			overflow: 'hidden',
			padding: 0,
			position: 'absolute',
			top: 20,
			width: 1,
		},
		row: {
			// border: '2px solid red',
			// padding: 0,
		}
	}),
);


type HeadCells = {
	id: string;
	label: string;
	sortable: boolean;
}

const headCells: HeadCells[] = [
	{ id: 'id', label: 'ID', sortable: true },
	{ id: 'username', label: 'Username', sortable: true },
	{ id: 'email', label: 'Email', sortable: true },
	{ id: 'lastLogin', label: 'Last login', sortable: true },
	{ id: 'active', label: 'Active', sortable: true },
	{ id: 'accessLevel', label: 'Role', sortable: true },
	{ id: 'actions', label: 'Actions', sortable: false},
];



interface UsersTableProps {
	users: User[];
	orderBy: string;
	order: Order;
	onDelete: ( userId: number ) => Promise<void>;
	changeOrder: ( order: any ) => void;
}

export const UsersTable: FunctionComponent<UsersTableProps> = observer( ( props: UsersTableProps ) => {
	const notifications = useContext(SnackbarsContext);
	const classes = useStyles();

	const createChangeOrderHandler = (property: any) => (event: React.MouseEvent<unknown>) => {
		props.changeOrder(property);
	};

	return (
		<TableWrapper>
			<Table>
				<TableHead>
					<TableRow>
						{
							headCells.map( cell => (
								<TableCell
									sortDirection={props.orderBy === cell.id ? props.order : false}
									style={{fontWeight: 'bold'}}
									key={cell.id}
								>
									{
										cell.sortable ? (
											<TableSortLabel
												active={props.orderBy === cell.id}
												direction={props.orderBy === cell.id ? props.order || 'desc'  : 'asc'}
												onClick={createChangeOrderHandler(cell.id)}
											>
												{cell.label}
												{props.orderBy === cell.id ? (
													<span className={classes.visuallyHidden}>
												{props.order === 'asc' ? 'sorted descending' : 'sorted ascending'}
											</span>
												) : null}
											</TableSortLabel>
										) : cell.label
									}
								</TableCell>
							) )
						}
					</TableRow>
				</TableHead>
				<TableBody>
					{props.users.map(user => {
						return (
							<TableRow key={user.id} className={classes.row}>
								<TableCell>
									{user.id}
								</TableCell>
								<TableCell>
									{user.username}
								</TableCell>
								<TableCell>
									{user.email}
								</TableCell>
								<TableCell>
									{user.lastLogin ? moment(user.lastLogin).fromNow() : '-'}
								</TableCell>
								<TableCell>
									<Box width={60} height={45}>
										<UserStatus notifications={notifications} userEntry={user} />
									</Box>
								</TableCell>
								<TableCell>
									<Box width={60} height={45}>
										<AccessLevelChip notifications={notifications} userEntry={user} />
									</Box>
								</TableCell>
								<TableCell>
									<Box display="flex">
										{/*<DeleteUser notifications={notifications} deletingUser={user} onDelete={props.onDelete} /> TODO: Verify that remove user will not remove tenders and calculations */}
										{/*<Spacer width={16} />*/}
										<ResetOtp notifications={notifications} resetingUser={user} />
									</Box>
								</TableCell>
							</TableRow>
						);
					})}
				</TableBody>
			</Table>
		</TableWrapper>
	)
} );

export default UsersTable;

export const usernameRegex = /^[a-zA-Z0-9]{3,30}$/;
export const usernameRegexDescription = '3-30 alphanumeric characters';

export const passwordRegex = /^(?=.*[a-z])(?=.*\d)[a-zA-Z\d$@!%*#?&]{8,30}$/;
export const passwordRegexDescription = '8-30 characters, at least 1 alpha and 1 digit';

export const appNameRegex = /^[a-zA-Z0-9]{3,30}$/;
export const appNameRegexDescription = '3-30 alphanumeric characters';

export const clientNameRegex = /^[a-zA-Z0-9\s]{3,30}$/;
export const clientNameRegexDescription = '3-30 alphanumeric characters';

export const locationNameRegex = /^[a-zA-Z0-9\s]{3,30}$/;
export const locationNameRegexDescription = '3-30 alphanumeric characters';

export const exportNameRegex = /^[a-zA-Z0-9_\-\s]{3,50}$/;
export const exportNameRegexDescription = '3-50 alphanumeric characters, supported dashes and underscores';

export const locationIdRegex = /^[0-9]+$/;

export const uriRegex = new RegExp(
	"^" +
	// protocol identifier
	"(https?|ftp)://" +
	// user:pass authentication
	"(\\S+(:\\S*)?@)?" +
	"(" +
	// IP address dotted notation octets
	"([1-9]|[1-9]\\d|1\\d\\d|2[0-1]\\d|22[0-3])" +
	"(\\.([0-9]|[1-9]\\d|1\\d\\d|2[0-4]\\d|25[0-5])){2}" +
	"(\\.([1-9]|[1-9]\\d|1\\d\\d|2[0-4]\\d|25[0-4]))" +
	"|" +
	// host name
	"(([a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)" +
	// domain name
	"(\\.([a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)*" +
	")" +
	// port number
	"(:\\d{2,5})?" +
	// resource path
	"(/[^\\s]*)?" +
	"$",
	"i",
);

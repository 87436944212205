import React, {FunctionComponent} from "react";
import {observer} from "mobx-react";
import Typography from "@material-ui/core/Typography";
import {FilterProps} from "./filter_props";


export const FilterLabel: FunctionComponent<FilterProps> = observer( ( props: FilterProps ) => {
	return <Typography variant="caption" color="textSecondary">
		Filter
	</Typography>
} );

import React, {FunctionComponent, useEffect, useState} from "react";
import {observer} from "mobx-react";
import {action, observable} from "mobx";
import User from "../../models/user";


export interface UserCtx {
	user: User;
}

export const UserContext = React.createContext({} as UserCtx);

export const UserManager: FunctionComponent<{}> = observer( (props ) => {
	const [store] = useState(() =>
		observable( {
			user: undefined as unknown as User,
		} as UserCtx )
	);

	useEffect( () => {
		action( () => {
			store.user = new User( ( window as any ).config.user );
		} )()
	}, [store] )

	return(
		<UserContext.Provider value={store}>
			{store.user && props.children}
		</UserContext.Provider>
	)
} );

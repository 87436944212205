import * as React from 'react';
import styled from 'styled-components';


/**
 * @deprecated Use container instead
 */
export const RightAlign = styled.div`
	width: 100%;
	text-align: right;
`;

/**
 * @deprecated Use container instead
 */
export const CenterAlign = styled.div`
	width: 100%;
	text-align: center;
`;

export const CardLoaderWrapper = styled.div`
	width: 100%;
	height: 5px;
	position: relative;
	margin-bottom: -5px;
`;

export const TableWrapper = styled.div`
	width: 100%;
	overflow-x: auto;
`;

export const HiddenContainer = styled.div`
	visibility: hidden;
`;


export interface ContainerProps {
	margin?: number;
	padding?: number;
	align?: string;

	className?: string;
}

export const ContainerElem: React.StatelessComponent<ContainerProps> = props => (
	<div className={props.className}>{props.children} </div>
);

export const Container = styled( ContainerElem )`
	width: 100%;
	margin: ${props => props.margin ? `${props.margin}px` : `auto`};
	padding: ${props => props.padding ? `${props.padding}px` : `auto`};
	text-align: ${props => props.align || 'initial'};
`;


export interface InlineContainerProps {
	width?: number;
	height?: number;
	margin?: number;

	className?: string;
}

export const InlineContainerElem: React.StatelessComponent<InlineContainerProps> = props => (
	<div className={props.className}>{props.children} </div>
);

export const InlineContainer = styled( InlineContainerElem )`
	display: inline-block;
	padding: 4px;
	text-align: center;
	text-decoration: none;
	width: ${props => props.width ? `${props.width}px` : `initial`};
	height: ${props => props.height ? `${props.height}px` : `initial`};
	margin: ${props => props.margin ? `${props.margin}px` : `initial`};
`;


import * as React from 'react';
import {Component} from 'react';
import {observer} from 'mobx-react';
import {action, makeObservable, observable} from "mobx";
import axios from 'axios';

import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import {FormDialogWrapper} from "../components/form_dialog_wrapper";
import ChangeEmailForm from "../components/forms/change_email_form";
import {UserContext} from "./contexts/user_ctx";
import {Box} from "@material-ui/core";
import {Snackbars} from "./contexts/snackbar_ctx";

interface ChangeEmailProps {
	notifications: Snackbars;
}



@observer
export default class ChangeEmail extends Component<ChangeEmailProps, {}> {
	static contextType = UserContext; // To use multiple context use func component
	processing: boolean = false;

	constructor( props: any, context: any ) {
		super( props, context );

		makeObservable(this, {
			processing: observable,
		});
	}

	private changeEmailDialogRef: FormDialogWrapper | null = null;

	public render() {
		return (
			<Box>
				<IconButton onClick={this.changeEmail}>
					<EditIcon />
				</IconButton>

				<FormDialogWrapper
					ref={this.setChangeEmailDialogRef}
					name="Change email"
					form={ChangeEmailForm}
					onSubmit={this.processChangeEmail}
					onCancel={this.onHide}
					processing={this.processing}
					formValues={{email: this.context.user.email}}
				/>
			</Box>
		);
	}

	private setChangeEmailDialogRef = ( ref: FormDialogWrapper ) => {
		this.changeEmailDialogRef = ref;
	};

	private changeEmail = () => {
		this.changeEmailDialogRef?.show();
	};

	private onHide = async () => {
		this.changeEmailDialogRef?.hide( null );
	};

	private processChangeEmail = async ( newEmail: string ) => {
		if( !this.processing ) {
			try {
				action( () => {
					this.processing = true;
				} )()

				const resp = await axios( {
					method: 'patch',
					url: '/api/v1/users/change-email',
					data: {
						email: newEmail
					}
				} );

				if( resp.data.success ) {
					action( () => {
						this.context.user.email = newEmail;
						this.props.notifications.showSnackbar( "Email has been changed successfully" );
						this.changeEmailDialogRef?.hide( null, true );
					} )()
				} else {
					throw new Error( "Invalid response" );
				}
			} catch( err ) {
				console.error(err);
				this.props.notifications.showSnackbar( "Can not change email", "error" );
			} finally {
				action( () => {
					this.processing = false;
				} )()
			}
		}
	};

}
import React, {FunctionComponent, useState} from "react";
import {observer} from "mobx-react";
import {TextField} from "@material-ui/core";
import * as _ from "lodash";
import {Autocomplete} from "@material-ui/lab";
import {countries} from "countries-list";
import {action, observable} from "mobx";
import {FilterProps} from "./filter_props";

const countriesList = _.map( _.keys(countries), k => ( { code: k, ...countries[k] } ) );



export const FilterCountry: FunctionComponent<FilterProps> = observer( ( props: FilterProps ) => {
	const [store] = useState(() =>
		observable( {
			country: props.defaultValue || "" as string,
		} )
	);

	const onCountryChange = async ( e: any, v: any ) => {
		action( () => {
			if( v == null ) {
				store.country = "";
			} else {
				store.country = v.code;
			}

			props.onChange?.( props.name, store.country );
		} )()
	};

	return (
		<Autocomplete
			options={countriesList}
			getOptionLabel={(option) => option === null as any ? '' : `${option.emoji} ${option.name}`}
			fullWidth={true}
			renderInput={(params) =>
				<TextField {...params} label={`Country`} variant="outlined" size="small" />
			}
			size="small"
			value={_.find(countriesList, {code: store.country}) || null}
			onChange={onCountryChange}
			style={{minWidth: 160}}
		/>
	)
} );

import * as React from 'react';
import {Component} from 'react';
import Wrapper from '../../../components/public/wrapper';
import ResetPasswordForm from '../../../components/forms/reset_password_form';
import {observer} from 'mobx-react';
import {Box} from "@material-ui/core";

@observer
class ResetPwd extends Component<any, any> {
	render() {

		return (
			<Wrapper>
				<Box className="LandingPanelDescription">Reset password</Box>

				<ResetPasswordForm/>
			</Wrapper>
		);
	}
}

export default ResetPwd;

import * as React from 'react';
import {Component} from 'react';
import {observer} from 'mobx-react';
import Button from '@material-ui/core/Button';
import {makeObservable, observable} from "mobx";
import axios from 'axios';

import User from "../models/user";
import {FormDialogWrapper} from "../components/form_dialog_wrapper";
import {EnterPasswordForm} from "../components/forms/enter_password_form";
import {UserContext} from "./contexts/user_ctx";
import {Snackbars} from "./contexts/snackbar_ctx";


interface ResetOtpProps {
	resetingUser: User;
	notifications: Snackbars;
}


@observer
export default class ResetOtp extends Component<ResetOtpProps, {}> {
	static contextType = UserContext; // To use multiple context use func component
	processing: boolean = false;

	constructor( props: any, context: any ) {
		super( props, context );

		makeObservable(this, {
			processing: observable,
		});
	}

	private enterPasswordDialogRef: FormDialogWrapper | null = null;

	public render() {
		return (
			<>
				<Button
					variant="contained"
					size="small"
					color="primary"
					onClick={this.showEnterPasswordDialog}
					disabled={this.processing}
				>
					Reset OTP
				</Button>

				<FormDialogWrapper
					ref={this.setEnterPasswordDialogRef}
					name={`Reset user ${this.props.resetingUser.username} OTP`}
					form={EnterPasswordForm}
					onSubmit={this.ResetOtp}
					onCancel={this.hideEnterPasswordDialog}
					processing={this.processing}
				/>
			</>
		);
	}

	private showEnterPasswordDialog = () => {
		this.enterPasswordDialogRef?.show();
	};

	private hideEnterPasswordDialog = async () => {
		this.enterPasswordDialogRef?.hide( null );
	};

	private setEnterPasswordDialogRef = ( ref: FormDialogWrapper ) => {
		this.enterPasswordDialogRef = ref;
	};

	private ResetOtp = async ( password: string ) => {
		if( !this.processing ) {
			try {
				this.processing = true;

				const resp = await axios( {
					method: 'patch',
					url: '/api/v1/users/reset-otp',
					data: {
						user_id: this.props.resetingUser.id,
						current_password: password
					}
				} );

				if( resp.data.success ) {
					this.props.notifications.showSnackbar( "Reset link has been sent successfully" );
				} else {
					throw new Error( "Invalid response" );
				}
			} catch (err) {
				console.error(err);
				this.props.notifications.showSnackbar( "Can not send reset link", "error" );
			} finally {
				this.processing = false;
			}
		}
	};

}
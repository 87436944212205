import * as React from 'react';
import {FunctionComponent, SyntheticEvent, useContext, useState} from 'react';
import {observer} from 'mobx-react';
import {
	Button,
	createStyles,
	FormControl,
	InputLabel,
	makeStyles,
	MenuItem,
	Paper,
	Select,
	TextField,
	Theme,
	Typography
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import {Controller, useForm} from "react-hook-form";
import * as _ from "lodash";
import {countries} from "countries-list";
import {Spacer} from "../spacer";
import Tender from "../../models/tender";
import {Autocomplete} from "@material-ui/lab";
import {action, observable} from "mobx";
import {KeyboardDatePicker} from "@material-ui/pickers";
import {UserContext} from "../../containers/contexts/user_ctx";
import {TextInput} from "./form_text_input";

const countriesList = _.map( _.keys(countries), k => ( { code: k, ...countries[k] } ) );

// Copy from algorithm transmission
const ROAMING_ZONE_1 = [ 'AT', 'AD', 'BE', 'BG', 'HR', 'CY', 'CZ', ' DK', 'EE', 'FI', 'FR', 'GI', 'GR', 'GF', 'GP', 'ES', 'NL', 'IE', 'IS', 'IT', 'LI', 'LT', 'LU', 'LV', 'MT', 'MQ', 'MC', 'DE', 'NO', 'PT', 'RE', 'SM', 'SK', 'SI', 'SE', 'HU', 'GB', 'VA' ];
// tslint:disable-next-line
const ROAMING_ZONE_2 = [ 'AL', 'BY', 'BA', 'EG', 'GL', 'CA', 'MK', 'MD', 'RU', 'RS', 'CH', 'TN', 'TR', 'UA', 'US' ];
// tslint:disable-next-line
const ROAMING_ZONE_3: null = null; // Rest of countries

const allowedTerritory = ( code: string ) => {
	return ROAMING_ZONE_1.includes(code) || code === "PL";
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		paper: {
			padding: 16,
		},
	}),
);


interface TenderFormProps {
	onSubmit?: ( name: string, ...x: any[] ) => Promise<void>;
	processing: boolean;
	result?: number;
	tender?: Tender;
}


export const TenderForm: FunctionComponent<TenderFormProps> = observer( ( props: TenderFormProps ) => {
	const classes = useStyles();
	const {user} = useContext(UserContext);
	const { register, handleSubmit, control, errors } = useForm( {
		defaultValues: props.tender || {
			chanceToWin: "unknown",
		} as any,
	} );

	const [store] = useState(() =>
		observable( {
			country: props.tender?.locationCountry || "PL" as string,
		} )
	);

	const onSubmit = async ( e: SyntheticEvent ) => {
		await handleSubmit(async ( data: any ) => {
			await props.onSubmit?.( data );
		})(e);
	};

	const onCountryChange = async ( e: any, v: any ) => {
		action( () => {
			if( v == null ) {
				store.country = "";
			} else {
				store.country = v.code;
			}
		} )()
	};

	return (
		<form onSubmit={onSubmit}>
			<Spacer height={16} />

			<Grid container={true} spacing={2}>
				<Grid item={true} xs={12} md={3}>
					<Typography variant="h6" align={"right"}>
						Base information
					</Typography>

					<Typography variant="body1" color="textSecondary" align={"right"}>
						Provide base information
					</Typography>
				</Grid>
				<Grid item={true} xs={12} md={9}>
					<Paper className={classes.paper} elevation={2}>
						<Grid container={true} spacing={2}>
							<Grid item={true} xs={12} md={12}>
								<TextInput
									register={register}
									disabled={!!props.tender && !user.isAdmin}
									name="name"
									label="Tender name"
									required={true}
									defaultValue={props.tender?.name}
								/>
							</Grid>
							<Grid item={true} xs={12} md={6}>
								<TextInput
									register={register}
									disabled={!!props.tender && !user.isAdmin}
									name="crmLugProjectNo"
									label="CRM lug project no"
									required={true}
									defaultValue={props.tender?.crmLugProjectNo}
								/>
							</Grid>
							<Grid item={true} xs={12} md={6}>
								<TextInput
									register={register}
									name="lluContactPerson"
									label="LLU contact person"
									required={true}
									defaultValue={props.tender?.lluContactPerson}
								/>
							</Grid>
							<Grid item={true} xs={12} md={6}>
								<FormControl variant="outlined" fullWidth={true} error={Boolean(errors.locationTerrainType)}>
									<InputLabel id="terrain-label" error={Boolean(errors.locationTerrainType)}>{`State *`}</InputLabel>
									<Controller
										as={
											<Select
												labelId="state-label"
												id="state-select-outlined"
												label={`State`}
												fullWidth={true}
											>
												<MenuItem value="">-</MenuItem>
												<MenuItem value="active">{`Active`}</MenuItem>
												<MenuItem value="win">{`Win`}</MenuItem>
												<MenuItem value="lost">{`Lost`}</MenuItem>
											</Select>
										}
										name="state"
										rules={{ required: true }}
										control={control}
										defaultValue={props.tender?.state || ""}
									/>
								</FormControl>
							</Grid>
							<Grid item={true} xs={12} md={6}>
								{/*win tender state*/}
							</Grid>
							<Grid item={true} xs={12} md={6}>
								<FormControl variant="outlined" fullWidth={true} error={Boolean(errors.locationTerrainType)}>
									<InputLabel id="terrain-label" error={Boolean(errors.locationTerrainType)}>{`Chance to win *`}</InputLabel>
									<Controller
										as={
											<Select
												labelId="chance-to-win-label"
												id="chance-to-win-select-outlined"
												label={`Chance to win`}
												fullWidth={true}
											>
												<MenuItem value="unknown">{`Unknown`}</MenuItem>
												<MenuItem value="low">{`Low`}</MenuItem>
												<MenuItem value="moderate">{`Moderate`}</MenuItem>
												<MenuItem value="high">{`High`}</MenuItem>
												<MenuItem value="very-high">{`Very high`}</MenuItem>
											</Select>
										}
										name="chanceToWin"
										rules={{ required: true }}
										control={control}
										defaultValue={props.tender?.chanceToWin || ""}
									/>
								</FormControl>
							</Grid>
							<Grid item={true} xs={12} md={6}>
								<TextInput
									register={register}
									name="chanceToWinDescription"
									label="Chance to win description"
									required={false}
									defaultValue={props.tender?.chanceToWinDescription}
									multiline={true}
									rows={4}
								/>
							</Grid>
						</Grid>
					</Paper>
				</Grid>
			</Grid>

			<Spacer height={32} />

			<Grid container={true} spacing={2}>
				<Grid item={true} xs={12} md={3}>
					<Typography variant="h6" align={"right"}>
						Client
					</Typography>

					<Typography variant="body1" color="textSecondary" align={"right"}>
						Provide client information
					</Typography>
				</Grid>
				<Grid item={true} xs={12} md={9}>
					<Paper className={classes.paper} elevation={2}>
						<Grid container={true} spacing={2}>
							<Grid item={true} xs={12} md={6}>
								<TextInput
									register={register}
									disabled={!!props.tender && !user.isAdmin}
									name="clientName"
									label="Name"
									required={true}
									defaultValue={props.tender?.clientName}
								/>
							</Grid>
							<Grid item={true} xs={12} md={6}>
								<TextInput
									register={register}
									name="clientContactPerson"
									label="Contact person"
									required={true}
									defaultValue={props.tender?.clientContactPerson}
								/>
							</Grid>
							<Grid item={true} xs={12} md={6}>
								<TextInput
									register={register}
									name="clientTel"
									label="Telephone number"
									required={true}
									defaultValue={props.tender?.clientTel}
								/>
							</Grid>
							<Grid item={true} xs={12} md={6}>
								<TextInput
									register={register}
									name="clientMail"
									label="E-Mail"
									required={true}
									defaultValue={props.tender?.clientMail}
								/>
							</Grid>
						</Grid>
					</Paper>
				</Grid>
			</Grid>

			<Spacer height={32} />

			<Grid container={true} spacing={2}>
				<Grid item={true} xs={12} md={3}>
					<Typography variant="h6" align={"right"}>
						Commerce
					</Typography>

					<Typography variant="body1" color="textSecondary" align={"right"}>
						Provide commerce information
					</Typography>
				</Grid>
				<Grid item={true} xs={12} md={9}>
					<Paper className={classes.paper} elevation={2}>
						<Grid container={true} spacing={2}>
							<Grid item={true} xs={12} md={6}>
								<TextInput
									register={register}
									disabled={!!props.tender && !user.isAdmin}
									name="commerceTeamName"
									label="Team name"
									required={true}
									defaultValue={props.tender?.commerceTeamName}
								/>
							</Grid>
							<Grid item={true} xs={12} md={6}>
								<TextInput
									register={register}
									name="commerceTrader"
									label="Trader"
									required={true}
									defaultValue={props.tender?.commerceTrader}
								/>
							</Grid>
							<Grid item={true} xs={12} md={6}>
								<TextInput
									register={register}
									name="commerceTel"
									label="Telephone number"
									required={true}
									defaultValue={props.tender?.commerceTel}
								/>
							</Grid>
							<Grid item={true} xs={12} md={6}>
								<TextInput
									register={register}
									name="commerceMail"
									label="E-Mail"
									required={true}
									defaultValue={props.tender?.commerceMail}
								/>
							</Grid>
						</Grid>
					</Paper>
				</Grid>
			</Grid>

			<Spacer height={32} />
			
			<Grid container={true} spacing={2}>
				<Grid item={true} xs={12} md={3}>
					<Typography variant="h6" align={"right"}>
						Location
					</Typography>

					<Typography variant="body1" color="textSecondary" align={"right"}>
						Provide location information
					</Typography>
				</Grid>
				<Grid item={true} xs={12} md={9}>
					<Paper className={classes.paper} elevation={2}>
						<Grid container={true} spacing={2}>
							<Grid item={true} xs={12} md={6}>
								<Autocomplete
									options={countriesList}
									getOptionLabel={(option) => option === null as any ? '' : `${option.emoji} ${option.name} (${option.code})`}
									getOptionDisabled={(option) => option === null as any ? false : (  user.isAdmin ? false : !allowedTerritory(option.code) )}
									fullWidth={true}
									renderInput={(params) =>
										<TextField ref={register} {...params} label={`Country or territory (ISO 3166-1)`} variant="outlined" required={true} />
									}
									value={_.find(countriesList, {code: store.country}) || null}
									onChange={onCountryChange}
									disabled={!!props.tender}
								/>
								<input ref={register} type="hidden" value={store.country} name="locationCountry" />
							</Grid>
							<Grid item={true} xs={12} md={6}>
								<TextInput
									register={register}
									disabled={!!props.tender && !user.isAdmin}
									name="locationCity"
									label="City"
									required={true}
									defaultValue={props.tender?.locationCity}
								/>
							</Grid>
							<Grid item={true} xs={12} md={6}>
								<TextInput
									register={register}
									name="locationDistrictOrStreet"
									label="District or street"
									required={true}
									defaultValue={props.tender?.locationDistrictOrStreet}
								/>
							</Grid>
							<Grid item={true} xs={12} md={6}>
								<FormControl variant="outlined" fullWidth={true} error={Boolean(errors.locationTerrainType)}>
									<InputLabel id="terrain-label" error={Boolean(errors.locationTerrainType)}>{`Buildings density *`}</InputLabel>
									<Controller
										as={
											<Select
												labelId="terrain-label"
												id="terrain-select-outlined"
												label={`Buildings density`}
												fullWidth={true}
											>
												<MenuItem value="">-</MenuItem>
												<MenuItem value="dense">{`Dense urban buildings`}</MenuItem>
												<MenuItem value="moderate">{`Moderate urban buildings`}</MenuItem>
												<MenuItem value="scattered">{`Scattered urban buildings`}</MenuItem>
												<MenuItem value="highway">{`Highway`}</MenuItem>
												<MenuItem value="tunnel">{`Tunnel`}</MenuItem>
											</Select>
										}
										name="locationTerrainType"
										rules={{ required: true }}
										control={control}
										defaultValue={props.tender?.locationTerrainType || ""}
									/>
								</FormControl>
							</Grid>
						</Grid>
					</Paper>
				</Grid>
			</Grid>

			<Spacer height={32} />

			<Grid container={true} spacing={2}>
				<Grid item={true} xs={12} md={3}>
					<Typography variant="h6" align={"right"}>
						Deadlines
					</Typography>

					<Typography variant="body1" color="textSecondary" align={"right"}>
						Provide deadlines information
					</Typography>
				</Grid>
				<Grid item={true} xs={12} md={9}>
					<Paper className={classes.paper} elevation={2}>
						<Grid container={true} spacing={2}>
							<Grid item={true} xs={12} md={4}>
								<Controller
									as={
										<KeyboardDatePicker
											inputVariant="outlined"
											fullWidth
											openTo="year"
											label={`For asking questions`}
											views={["year", "month", "date"]}
											format="YYYY-MM-DD"
											// maxDate={moment().subtract(1, 'years').toDate()}
											// initialFocusedDate={moment().subtract(1, 'years').toDate()}
											TextFieldComponent={ ( props: any ) => <TextField
												required
												{...props}
											/>}
											onChange={undefined as any/*FIX*/}
											value={undefined as any/*FIX*/}
										/>
									}
									name="deadlineForAskingQuestions"
									rules={{ required: true }}
									control={control}
									defaultValue={props.tender?.deadlineForAskingQuestions ? new Date(props.tender?.deadlineForAskingQuestions) : null}
								/>
							</Grid>
							<Grid item={true} xs={12} md={4}>
								<Controller
									as={
										<KeyboardDatePicker
											inputVariant="outlined"
											fullWidth
											openTo="year"
											label={`For offer presentation to client`}
											views={["year", "month", "date"]}
											format="YYYY-MM-DD"
											// maxDate={moment().subtract(1, 'years').toDate()}
											// initialFocusedDate={moment().subtract(1, 'years').toDate()}
											TextFieldComponent={ ( props: any ) => <TextField
												required
												{...props}
											/>}
											onChange={undefined as any/*FIX*/}
											value={undefined as any/*FIX*/}
										/>
									}
									name="deadlineForOfferPresentationToClient"
									rules={{ required: true }}
									control={control}
									defaultValue={props.tender?.deadlineForOfferPresentationToClient ? new Date(props.tender?.deadlineForOfferPresentationToClient) : null}
								/>
							</Grid>
							<Grid item={true} xs={12} md={4}>
								<Controller
									as={
										<KeyboardDatePicker
											inputVariant="outlined"
											fullWidth
											openTo="year"
											label={`For decision`}
											views={["year", "month", "date"]}
											format="YYYY-MM-DD"
											// maxDate={moment().subtract(1, 'years').toDate()}
											// initialFocusedDate={moment().subtract(1, 'years').toDate()}
											TextFieldComponent={ ( props: any ) => <TextField
												required
												{...props}
											/>}
											onChange={undefined as any/*FIX*/}
											value={undefined as any/*FIX*/}
										/>
									}
									name="deadlineForDecision"
									rules={{ required: true }}
									control={control}
									defaultValue={props.tender?.deadlineForDecision ? new Date(props.tender?.deadlineForDecision) : null}
								/>
							</Grid>
							<Grid item={true} xs={12} md={6}>
								<Controller
									as={
										<KeyboardDatePicker
											inputVariant="outlined"
											fullWidth
											openTo="year"
											label={`For delivery`}
											views={["year", "month", "date"]}
											format="YYYY-MM-DD"
											// maxDate={moment().subtract(1, 'years').toDate()}
											// initialFocusedDate={moment().subtract(1, 'years').toDate()}
											TextFieldComponent={ ( props: any ) => <TextField
												required
												{...props}
											/>}
											onChange={undefined as any/*FIX*/}
											value={undefined as any/*FIX*/}
										/>
									}
									name="deadlineForDelivery"
									rules={{ required: true }}
									control={control}
									defaultValue={props.tender?.deadlineForDelivery ? new Date(props.tender?.deadlineForDelivery) : null}
								/>
							</Grid>
							<Grid item={true} xs={12} md={6}>
								<Controller
									as={
										<KeyboardDatePicker
											inputVariant="outlined"
											fullWidth
											openTo="year"
											label={`For acceptance date for installed system`}
											views={["year", "month", "date"]}
											format="YYYY-MM-DD"
											// maxDate={moment().subtract(1, 'years').toDate()}
											// initialFocusedDate={moment().subtract(1, 'years').toDate()}
											TextFieldComponent={ ( props: any ) => <TextField
												required
												{...props}
											/>}
											onChange={undefined as any/*FIX*/}
											value={undefined as any/*FIX*/}
										/>
									}
									name="deadlineForAcceptanceDateForInstalledSystem"
									rules={{ required: true }}
									control={control}
									defaultValue={props.tender?.deadlineForAcceptanceDateForInstalledSystem ? new Date(props.tender?.deadlineForAcceptanceDateForInstalledSystem) : null}
								/>
							</Grid>
						</Grid>
					</Paper>
				</Grid>
			</Grid>

			<Spacer height={32} />

			<Grid container={true} spacing={2}>
				<Grid item={true} xs={12} />
				<Grid item={true} xs={12} md={3} />
				<Grid item={true} xs={12} md={3}>
					<Button
						type="submit"
						color="primary"
						fullWidth={true}
						variant="contained"
						size="large"
						disabled={props.processing}
						style={{height: 64}}
					>
						{props.tender ? "Save" : "Add"}
					</Button>
				</Grid>
				<Grid item={true} xs={12} />
			</Grid>
		</form>
	)
} );

export default TenderForm;

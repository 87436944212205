import * as React from 'react';
import {Component} from 'react';
import {observer} from 'mobx-react';
import styled from 'styled-components';
import OfflineIcon from '@material-ui/icons/CloudOff';
import Typography from '@material-ui/core/Typography';

export const OfflineWrapper = styled.div`
	width: 100%;
	text-align: center;
`;

export interface OfflineProps {
}

@observer
export class Offline extends Component<OfflineProps, {}> {

	public render() {
		return (
			<OfflineWrapper>
				<OfflineIcon style={{ color: '#ccc', width: '40%', height: '40%' }}/><br/>
				<Typography variant="h5" gutterBottom={true}>
					There is no internet connection
				</Typography>

			</OfflineWrapper>
		);
	}

}
import * as React from 'react';
import {Component} from 'react';
import {observer} from 'mobx-react';
import {Chip, CircularProgress} from '@material-ui/core';
import User, {AccessLevel} from "../models/user";
import {action, makeObservable, observable} from "mobx";
import axios from 'axios';
import {UserContext} from "./contexts/user_ctx";
import {Snackbars} from "./contexts/snackbar_ctx";
import {FormDialogWrapper} from "../components/form_dialog_wrapper";
import {AccessLevelForm} from "../components/forms/access_level_form";

interface UserAdminStatusProps {
	userEntry: User;
	notifications: Snackbars;
}


@observer
export default class AccessLevelChip extends Component<UserAdminStatusProps, {}> {
	static contextType = UserContext; // To use multiple context use func component
	processing: boolean = false;

	constructor( props: any, context: any ) {
		super( props, context );

		makeObservable(this, {
			processing: observable,
		});
	}

	private AccessLevelDialogRef: FormDialogWrapper | null = null;

	public render() {
		if ( !this.processing ) {
			return <>
				<Chip
					label={this.props.userEntry.humanAccessLevel}
					onClick={this.showAccessLevelDialog}
					disabled={this.context.user.id === this.props.userEntry.id}
					style={{marginTop: 6}}
					color={this.props.userEntry.accessLevel === AccessLevel.admin ? "secondary" : "default"}
				/>

				<FormDialogWrapper
					ref={this.setAccessLevelDialogRef}
					name={`Change ${this.props.userEntry.username} role`}
					form={AccessLevelForm}
					onSubmit={this.changeLevel}
					onCancel={this.hideAccessLevelDialog}
					processing={this.processing}
					formData={{
						accessLevel: this.props.userEntry.accessLevel
					}}
				/>
			</>
		} else {
			return (
				<CircularProgress />
			)
		}
	}

	private showAccessLevelDialog = () => {
		this.AccessLevelDialogRef?.show();
	};

	private hideAccessLevelDialog = async () => {
		this.AccessLevelDialogRef?.hide( null );
	};

	private setAccessLevelDialogRef = ( ref: FormDialogWrapper ) => {
		this.AccessLevelDialogRef = ref;
	};

	private changeLevel = async ( accessLevel: string ) => {
		if( !this.processing ) {
			try {
				action( () => {
					this.processing = true;
				} )()

				const resp = await axios( {
					method: 'patch',
					url: '/api/v1/users/change-access-level',
					data: {
						user_id: this.props.userEntry.id,
						access_level: Number(accessLevel),
					}
				} );

				this.props.userEntry.accessLevel = Number(accessLevel);

				if( resp.data.success ) {
					action( () => {
						// this.props.userEntry.isAdmin = value;
						this.props.notifications.showSnackbar( "User role has been changed successfully" );
					} )()
				} else {
					throw new Error( "Invalid response" );
				}
			} catch (err) {
				console.error(err);
				this.props.notifications.showSnackbar( "Can not change user role", "error" );
			} finally {
				action( () => {
					this.processing = false;
				} )()
			}
		}
	}
}
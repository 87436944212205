import * as _ from 'lodash';


export abstract class BaseModel {
	deserializeFields( json: any, props: string[], target: any = null ): void {
		_.each( props, p => {
			if( _.has( json, p ) ) {
				(target || this as any)[ _.camelCase( p ) ] = json[ p ];
			}
			return true;
		} );
	}

	abstract deserialize( json: any ): void;
}

import * as React from 'react';
import {Component} from 'react';
import Wrapper from '../../../components/public/wrapper';
import LoginForm from '../../../components/forms/login_form';
import {observer} from 'mobx-react';
import {Box} from "@material-ui/core";


@observer
class Login extends Component<any, any> {
	render() {
		return (
			<Wrapper>
				<Box className="LandingPanelDescription">Login</Box>

				<LoginForm/>
			</Wrapper>
		);
	}
}

export default Login;

import * as React from 'react';
import {Component} from 'react';
import {observer} from 'mobx-react';
import {action, makeObservable, observable} from 'mobx';
import {Waypoint} from 'react-waypoint';
import {Drawer} from "@material-ui/core";
import {withRouter} from "react-router";
import Typography from '@material-ui/core/Typography';

import {Redirect, Route, Switch} from 'react-router-dom';
import AppHeader from "./app_header";

import AppMenu from "./app_menu";
import MenuItems from "./menu_items";

import BottomMenuItems from "./bottom_menu_items";
import './app_screen.css';

import bgImg from '../../static/header-bg.png';
import biotLogoImg from '../../static/lug.png';
import Users from "../../containers/screens/users";
import {UserBox} from "../user_box";
import {MyAccount} from "../../containers/screens/my_account";
import Calc from "../../containers/screens/calc";
import Calculations from "../../containers/screens/calculations";
import HistoryDetails from "../../containers/screens/calculation_details";
import TendersList from "../../containers/screens/tenders_list";
import TenderCreator from "../../containers/screens/tender_creator";
import TenderDetails from "../../containers/screens/tender_details";
import ReCalc from "../../containers/screens/recalc";
import {FullscreenContext} from "../../containers/contexts/fullscreen_ctx";
// import DashboardScreen from '../../containers/screens/dashboard';


// @inject( "showNotification" )
@observer
class AppScreen extends Component<any> {
	onTop = false;
	menuOpen = false;

	static contextType = FullscreenContext; // To use multiple context use func component

	constructor( props: any, context: any ) {
		super( props, context );

		makeObservable(this, {
			onTop: observable,
			menuOpen: observable,
		});
	}

	public render() {

		return (
			<div className="App">
				<UserBox
					onLogout={this.onLogout}
				/>

				<AppMenu bottomItems={<BottomMenuItems />}>
					<MenuItems />
				</AppMenu>

				<div className="HeaderBg mui-fixed" style={{ backgroundColor: '#000', backgroundImage: `url(${bgImg})` }}/>
				<div className="BodyBg" style={{ backgroundColor: '#ecebeb' }}/>

				<AppHeader
					onTop={this.onTop}
					onLogout={this.onLogout}
					onOpenMenu={this.onOpenMenu}
					onMyAccount={this.onMyAccount}
					onIndex={this.onIndex}
				/>

				<div className="AppFooter mui-fixed">
					<Typography className="Label" variant="caption">
						Powered by
					</Typography>
					&nbsp;&nbsp;
					<img src={biotLogoImg} alt="" style={{ height: 26, paddingBottom: 6 }} />
				</div>

				<div className="AppBox" style={{zIndex: this.context.fullscreen ? 10 : 5}}>
					<Waypoint
						onEnter={this.handlePageTopWayPointEnter}
						onLeave={this.handlePageTopWayPointLeave}
					/>

					<div className="AppContent">
						<Switch>
							<Route path="/" component={TendersList} exact={true} />
							<Route path="/create" component={TenderCreator} exact={true} />
							<Route path="/users" component={Users} exact={true} />

							<Route path="/tenders/:id/" component={TenderDetails} exact={true} />
							<Route path="/tenders/:id/calculations" component={Calculations} exact={true} />
							<Route path="/tenders/:id/calculations/create" component={Calc} exact={true} />
							<Route path="/tenders/:id/calculations/:calcId/recreate" component={ReCalc} exact={true} />
							<Route path="/tenders/:id/calculations/:calcId/details/" component={HistoryDetails} exact={true} />

							<Route path="/my-account" component={MyAccount}/>
							<Redirect path="*" to="/404" />
						</Switch>
					</div>
				</div>

				<Drawer
					open={this.menuOpen}
					onClose={this.onHideMenu}
					className="MenuDrawer"
					variant="temporary"
				>
					<div className="MenuDrawerContent">
						<UserBox
							insideDrawer={true}
							onLogout={this.onLogout}
						/>

						<AppMenu insideDrawer={true} bottomItems={<BottomMenuItems />}>
							<MenuItems />
						</AppMenu>
					</div>
				</Drawer>

			</div>
		);
	}

	private readonly handlePageTopWayPointEnter = action( () => {
		this.onTop = true;
	} );

	private readonly handlePageTopWayPointLeave = action( () => {
		this.onTop = false;
	} );

	private readonly onOpenMenu = action( () => {
		this.menuOpen = true;
	} );

	private readonly onHideMenu = action( () => {
		this.menuOpen = false;
	} );

	private readonly onLogout = action( () => {
		window.location.href = '/api/v1/auth/logout';
	} );

	private readonly onMyAccount = action( () => {
		this.props.history.push( '/my-account' );
	} );

	private readonly onIndex = action( () => {
		this.props.history.push( '/' );
	} );
}

export default withRouter( AppScreen );

import * as React from 'react';
import {FunctionComponent, SyntheticEvent} from 'react';
import {FormDialogProps} from "../form_dialog_wrapper";
import {observer} from "mobx-react";
import {useForm} from "react-hook-form";
import {Box, Button, Grid, TextField} from "@material-ui/core";
import {Spacer} from "../spacer";


export const ChangeEmailForm: FunctionComponent<FormDialogProps> = observer( ( props: FormDialogProps ) => {
	const { register, handleSubmit } = useForm( {
		defaultValues: {
			email: props.value?.email,
		},
	} );

	const onSubmit = async ( e: SyntheticEvent ) => {
		await handleSubmit(async ( data: any ) => {
			await props.onSubmit( data.email );
			e.preventDefault();
		})(e);
	};

	return (
		<form onSubmit={onSubmit}>
			<Grid container={true}>
				<Grid item={true} xs={12}>
					<TextField
						inputRef={register()}
						variant="outlined"
						type="email"
						name="email"
						placeholder="New email"
						label="New email"
						autoFocus={true}
						fullWidth={true}
						required={true}
					/>
				</Grid>

				<Spacer height={16} />

				<Grid item={true} xs={12} component={Box} display="flex" justifyContent={"flex-end"}>
					<Button onClick={props.onCancel} disabled={props.processing} variant="contained">
						Cancel
					</Button>

					<Spacer width={16} />

					<Button
						type="submit"
						color="secondary"
						variant="contained"
						disabled={props.processing}
					>
						Change email
					</Button>
				</Grid>

				<Spacer height={16} />
			</Grid>
		</form>
	)
} );

export default ChangeEmailForm;

import * as React from 'react';
import {Component} from 'react';
import {observer} from 'mobx-react';
import {ListItem, ListItemIcon, ListItemText} from '@material-ui/core';

import SettingsIcon from '@material-ui/icons/Settings';
import HelpIcon from '@material-ui/icons/Help';
import {withRouter} from "react-router";

interface BottomMenuItemsProps {
	insideDrawer?: boolean;
}

interface BottomMenuItemsState {

}

// @inject( "user" )
@observer
class BottomMenuItems extends Component<BottomMenuItemsProps, BottomMenuItemsState> {

	render() {

		return (
			<div>
				<div className="MenuLink">
					<ListItem button={true}>
						<ListItemIcon>
							<SettingsIcon />
						</ListItemIcon>
						<ListItemText primary={( "Settings" )}/>
					</ListItem>
				</div>

				<div className="MenuLink">
					<ListItem button={true}>
						<ListItemIcon>
							<HelpIcon />
						</ListItemIcon>
						<ListItemText primary={( "Help" )}/>
					</ListItem>
				</div>
			</div>
		);
	}
}

export default withRouter( BottomMenuItems as any );

import * as React from 'react';
import {Component} from 'react';
import {observer} from 'mobx-react';
import {Box, Button, Paper} from "@material-ui/core";
import Typography from '@material-ui/core/Typography';
import {Link as A} from "react-router-dom";

import authBgImg from '../../static/bg.jpg';
import './not_found.css';

@observer
class AuthWrapper extends Component<any, any> {

	render() {
		return (
			<div className="NotFoundBg" style={{ backgroundImage: ` url('${ authBgImg }') `}}>
				<div className="NotFoundPanelWrapper">
					<div className="NotFoundPanel">
						<svg viewBox="0 0 960 300">
							<symbol id="s-text">
								<text textAnchor="middle" x="50%" y="80%" className="g-ants">404</text>
							</symbol>

							<g className="g-ants">
								<use href="#s-text" className="NotFound404"></use>
								<use href="#s-text" className="NotFound404"></use>
								<use href="#s-text" className="NotFound404"></use>
								<use href="#s-text" className="NotFound404"></use>
								<use href="#s-text" className="NotFound404"></use>
							</g>
						</svg>

						<Paper className="NotFoundPaper">
							<Typography variant="h3" color="inherit">
								Not Found
							</Typography>

							<Box>
								<Typography variant="body2">
									<Button component={A} to="/" variant="contained" size="large" color="primary">
										Back to app
									</Button>
								</Typography>
							</Box>
						</Paper>
					</div>
				</div>
			</div>
		);
	}
}

export default AuthWrapper;

import React, {FunctionComponent} from "react";
import {observer} from "mobx-react";
import {InputProps as StandardInputProps, TextField} from "@material-ui/core";


export type Props = {
	register: any;
	name: string;
	label: string;
	disabled?: boolean;
	defaultValue?: string;
	max?: number,
	step?: number,
	InputProps?: StandardInputProps,
	className?: string;
	readonly: boolean;
}

export const CalcInputCount: FunctionComponent<Props> = observer( ( props: Props ) => {
	return <>
		<TextField
			inputRef={props.register ? props.register({
				valueAsNumber: true,
			}) : undefined}
			variant="outlined"
			type="number"
			name={props.register ? props.name : undefined}
			placeholder={props.label}
			label={props.label}
			fullWidth={true}
			disabled={props.disabled || props.readonly}
			required={true}
			defaultValue={props.defaultValue || "0"}
			inputProps={{min: 0, max: props.max, step: props.step || 1}}
			InputProps={props.InputProps}
			className={props.className}
		/>
		{
			props.disabled && (
				<input type="hidden" ref={props.register({valueAsNumber: true})} name={props.name} value={props.defaultValue || "0"} />
			)
		}
	</>
} );

import * as React from 'react';
import {FunctionComponent, useContext} from 'react';
import {observer} from 'mobx-react';

import AccountDetails from "../../components/account_details";
import MyAccountIcon from '@material-ui/icons/PermIdentity';
import {BaseScreen} from "../../components/base_screen";
import {UserContext} from "../contexts/user_ctx";


export const MyAccount: FunctionComponent<{}> = observer( () => {
	const {user} = useContext(UserContext);

	return (
		<BaseScreen icon={MyAccountIcon} title="My account">
			<AccountDetails user={user}/>
		</BaseScreen>
	)
} );

import * as React from 'react';
import {Component} from 'react';
import {observer} from 'mobx-react';
import {CircularProgress} from '@material-ui/core';
import User from "../models/user";
import Switch from '@material-ui/core/Switch';
import {action, makeObservable, observable} from "mobx";
import axios from 'axios';
import {UserContext} from "./contexts/user_ctx";
import {Snackbars} from "./contexts/snackbar_ctx";

interface UserStatusProps {
	userEntry: User;
	notifications: Snackbars;

}


@observer
export default class UserStatus extends Component<UserStatusProps, {}> {
	static contextType = UserContext; // To use multiple context use func component
	processing: boolean = false;

	constructor( props: any, context: any ) {
		super( props, context );

		makeObservable(this, {
			processing: observable,
		});
	}

	public render() {
		if ( !this.processing ) {
			return (
				<Switch
					checked={this.props.userEntry.isActive}
					onChange={this.changeStatus}
					disabled={this.context.user.id === this.props.userEntry.id}
				/>
			)
		} else {
			return (
				<CircularProgress />
			)
		}

	}

	private changeStatus = async ( e: any, value: boolean ) => {
		if( !this.processing ) {
			try {
				action( () => {
					this.processing = true;
				} )()

				const resp = await axios( {
					method: 'patch',
					url: '/api/v1/users/change-status',
					data: {
						user_id: this.props.userEntry.id,
						is_active: value
					}
				} );

				if( resp.data.success ) {
					action( () => {
						this.props.userEntry.isActive = value;
						this.props.notifications.showSnackbar( "User status has been changed successfully" );
					} )()
				} else {
					throw new Error( "Invalid response" );
				}
			} catch (err) {
				console.error(err);
				this.props.notifications.showSnackbar( "Can not change user status", "error" );
			} finally {
				action( () => {
					this.processing = false;
				} )()
			}
		}
	}
}
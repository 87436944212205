import * as React from 'react';
import {Component} from 'react';
import {observer} from 'mobx-react';
import {ListItem, ListItemIcon, ListItemText} from '@material-ui/core';

import {NavLink} from 'react-router-dom';
import UsersIcon from '@material-ui/icons/SupervisorAccount';
import GavelIcon from '@material-ui/icons/Gavel';
import PostAddIcon from '@material-ui/icons/PostAdd';

import {withRouter} from "react-router";
import {UserContext} from "../../containers/contexts/user_ctx";

interface MenuItemsProps {
	insideDrawer?: boolean;
}

interface MenuItemsState {

}

// @inject( "user" )
@observer
class MenuItems extends Component<MenuItemsProps, MenuItemsState> {
	static contextType = UserContext; // To use multiple context use func component

	render() {
		return (
			<div>
				<NavLink to="/create" className="MenuLink" activeClassName="ActiveMenuLink" exact={true}>
					<ListItem button={true}>
						<ListItemIcon>
							<PostAddIcon />
						</ListItemIcon>
						<ListItemText primary="New tender"/>
					</ListItem>
				</NavLink>

				<NavLink to="/" className="MenuLink" activeClassName="ActiveMenuLink" exact={true}>
					<ListItem button={true}>
						<ListItemIcon>
							<GavelIcon />
						</ListItemIcon>
						<ListItemText primary="Tenders"/>
					</ListItem>
				</NavLink>

				{
					this.context.user.isAdmin && (
						<NavLink to="/users" className="MenuLink" activeClassName="ActiveMenuLink" exact={true}>
							<ListItem button={true}>
								<ListItemIcon>
									<UsersIcon />
								</ListItemIcon>
								<ListItemText primary="Users"/>
							</ListItem>
						</NavLink>
					)
				}
			</div>
		);
	}
}

export default withRouter( MenuItems as any );

import * as React from 'react';
import {Component} from 'react';
import {observer} from 'mobx-react';
import {FABAction, FABActionItem, ScreenHeader} from "./screen_header";
import {Loading} from "./base_screen_components/loading";
import {Offline} from "./base_screen_components/offline";
import {EmptyList} from "./base_screen_components/empty_list";
import {ScreenError} from "./base_screen_components/error";
import Grid from '@material-ui/core/Grid';
import {LinearProgress} from '@material-ui/core';
import {BreadCrumb, ViewBreadcrumb} from "./breadcrumb";

import styled from 'styled-components';
import {action, makeObservable, observable} from "mobx";

import './base_screen.css';
import {HiddenContainer} from "./html_components";
import {FullscreenContext} from "../containers/contexts/fullscreen_ctx";


const BaseScreenLoaderWrapper = styled.div`
	width: calc( 100% + 40px );
	margin-top: -15px;
	margin-left: -20px;
	margin-right: -20px;
	z-index: 30;
	opacity: 0;
`;

const ContentWrapper = styled.div`
	width: calc( 100% + 10px );
	margin-left: -5px;
	margin-right: -5px;
`;

export interface ViewProps {
	icon: any;
	title: string;
	actionIcon?: any,
	onAction?: FABAction | FABActionItem[],
	loading?: boolean;
	loaded?: boolean;
	errorMessage?: string;
	emptyListMessage?: string;
	breadCrumbs?: BreadCrumb[];
}

@observer
export class BaseScreen extends Component<ViewProps, {}> {
	public static defaultProps: Partial<ViewProps> = {
		loaded: true
	};

	static contextType = FullscreenContext; // To use multiple context use func component

	showLoader: boolean = false;
	private showLoaderTimer: any;

	constructor( props: any, context: any ) {
		super( props, context );

		makeObservable(this, {
			showLoader: observable,
		});
	}

	public UNSAFE_componentWillMount() {
		this.showLoaderTimer = setTimeout( action( () => {
			this.showLoader = true;
		} ), 500 )
	}

	public componentWillUnmount() {
		clearTimeout( this.showLoaderTimer );
	}

	render() {
		console.log("this.context.fullWidth", this.context.fullWidth);

		return (
			<div className={ !this.context.fullscreen ? "View" : "FullScreenView" }>
			<div style={{position:"relative", zIndex: 5000}}>
				<Grid container={true} spacing={3}>
					<ScreenHeader
						icon={this.props.icon}
						name={this.props.title}
						actionIcon={this.props.actionIcon}
						onAction={this.props.onAction}
						disabled={this.props.loading || !this.props.loaded}
					/>

					<BaseScreenLoaderWrapper style={{
						opacity: this.props.loading && this.showLoader ? 1 : 0,
						transition: 'opacity 0.6s linear'
					}}>
						<LinearProgress/>
					</BaseScreenLoaderWrapper>

					{this.props.breadCrumbs && <ViewBreadcrumb items={this.props.breadCrumbs}/>}

					<Grid item={true} xs={12}>
						<ContentWrapper>
							{
								this.props.errorMessage ?
									<ScreenError>
										{this.props.errorMessage}
									</ScreenError>
									:
									this.props.emptyListMessage ?
										<EmptyList icon={this.props.actionIcon} action={this.props.onAction}>
											{this.props.emptyListMessage}
										</EmptyList>
										:
										!this.props.loaded && window.navigator.onLine === false ?
											<Offline/>
											:
											this.props.loading && <Loading/>
							}

							{
								!this.props.errorMessage && !this.props.emptyListMessage && this.props.loaded && window.navigator.onLine !== false && !this.props.loading ?
									this.props.children
									:
									<HiddenContainer>
										{this.props.children}
									</HiddenContainer>
							}
						</ContentWrapper>
					</Grid>
				</Grid>
			</div>
			</div>
		);
	}
}

import * as React from 'react';
import {Component} from 'react';
import {observer} from 'mobx-react';

import './app_header.css';

import {IconButton} from '@material-ui/core';

import MenuIcon from '@material-ui/icons/Menu';
import LogoutIcon from '@material-ui/icons/LockOpen';
import UserIcon from '@material-ui/icons/PermIdentity';

import logoImg from '../../static/logo-light.svg';
import {theme} from "../../index";

interface AppHeaderProps {
	onTop: boolean;
	onOpenMenu: () => void;
	onLogout: () => void;
	onMyAccount: () => void;
	onIndex: () => void;
}

interface AppHeaderState {

}

// @inject( "user" )
@observer
class AppHeader extends Component<AppHeaderProps, AppHeaderState> {
	render() {

		return (
			<div className={`AppHeader ${!this.props.onTop ? 'AppHeaderShadow' : ''}`}>
				<div className="AppHeaderContent mui-fixed">
					<div className="Hamburger">
						<IconButton onClick={this.props.onOpenMenu} style={{color: theme.palette.secondary.main}}>
							<MenuIcon />
						</IconButton>
					</div>

					<div className="AppHeaderLogo" onClick={this.props.onIndex}>
						<img src={logoImg} alt="" height="32" />
					</div>

					<div className="AppHeaderCenter"/>

					<div className="AppHeaderActions">
						<IconButton onClick={this.props.onMyAccount} style={{color: theme.palette.secondary.main}}>
							<UserIcon/>
						</IconButton>
						<IconButton onClick={this.props.onLogout} style={{color: theme.palette.secondary.main}} x-data="action-logout">
							<LogoutIcon/>
						</IconButton>
					</div>
				</div>
			</div>
		);
	}
}

export default AppHeader;

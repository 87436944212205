import React, {FunctionComponent, SyntheticEvent, useState} from "react";
import {observer} from "mobx-react";
import {Box, IconButton, InputAdornment, TextField, Tooltip} from "@material-ui/core";
import {FilterProps} from "./filter_props";
import FilterListIcon from '@material-ui/icons/FilterList';
import {useForm} from "react-hook-form";
import {observable} from "mobx";
import * as _ from "lodash";
import {LightTooltip} from "../light_tooltips";
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';


export const FilterText: FunctionComponent<FilterProps> = observer( ( props: FilterProps ) => {
	const [store] = useState(() =>
		observable( {
			tooltipOpen: false,
		} )
	);

	const { register, handleSubmit} = useForm( {
		defaultValues: {
			filter: props.defaultValue,
		},
	} );

	const onSubmit = async ( e: SyntheticEvent ) => {
		await handleSubmit(async ( data: any ) => {
			await props.onChange?.( props.name, data["filter"] );
		})(e);
	};

	const onFocus = () => {
		store.tooltipOpen = true;
	}

	const onBlur = () => {
		store.tooltipOpen = false;
	}

	return <form onSubmit={onSubmit}>
		<LightTooltip
			open={store.tooltipOpen}
			onClose={_.noop}
			onOpen={_.noop}
			placement="top-start"
			title={<Box display="flex" justifyContent="center" alignItems="center">
				<InfoOutlinedIcon fontSize="large" />
				&nbsp;
				<div>To search for part of a word use <strong>*</strong>, eg: <i>Caribbean Isl<strong>*</strong></i> or <i><b>*</b>Island</i> or <i><b>*</b>Isla<strong>*</strong></i></div>
			</Box>}
		>
			<TextField
				inputRef={register}
				variant="outlined"
				type="text"
				name={"filter"}
				placeholder={props.label}
				label={props.label}
				fullWidth={true}
				size="small"
				InputProps={{
					endAdornment: <InputAdornment position="end">
						<IconButton
							aria-label="Filter"
							edge="end"
							size="small"
							type="submit"
						>
							<FilterListIcon />
						</IconButton>
					</InputAdornment>
				}}
				onFocus={onFocus}
				onBlur={onBlur}
			/>
		</LightTooltip>
	</form>
} );

import * as React from 'react';
import {FunctionComponent, useContext, useEffect, useState} from 'react';
import {observer} from 'mobx-react';


import CalcIcon from '@material-ui/icons/Tune';
import {BaseScreen} from "../../components/base_screen";
import axios from "axios";
import {action, observable} from "mobx";
import CalcForm from "../../components/forms/calc_form";
import {SnackbarsContext} from "../contexts/snackbar_ctx";
import {useHistory, useParams} from "react-router-dom";
import Tender from "../../models/tender";
import Calculation from "../../models/calculation";
import {UserContext} from "../contexts/user_ctx";
import {formatPrice} from "../../utils/format_price";


const Calc: FunctionComponent<{}> = observer( () => {
	const notifications = useContext(SnackbarsContext);
	const { id } = useParams() as any;
	const {user} = useContext(UserContext);
	const history = useHistory();
	const [store] = useState(() =>
		observable( {
			tender: undefined as undefined | Tender,
			currentCalculation: undefined as undefined | Calculation,
			loading: false,
			loaded: false,
			processing: false,
			plnToEur: 0,
			result: undefined as undefined | number,
			resultCurrency: undefined as undefined | string,
		} )
	);

	const onSubmit = async ( name: string, params: any ) => {
		action( () => {
			store.processing = true;
		} )()
		try {
			const { data } = await axios( {
				method: 'post',
				url: '/api/v1/calculate',
				data: {
					tenderId: id,
					name,
					params,
				}
			} );

			action( () => {
				store.result = Number(data.result);
				if( user.isAdmin ) {
					store.currentCalculation = new Calculation(data.calculation);
				}
				store.resultCurrency = params.output_currency;
				notifications.showSnackbar( `Calculated successfully, new price: ${formatPrice(store.result)} ${params.output_currency}`, "success" );
				history.push( `/tenders/${store.tender?.id}/calculations/${data.id}/details/` );
			} )()
		} catch (e) {
			if( e.response?.data?.status === -4 ) {
				notifications.showSnackbar( e.response?.data?.message, "warning" );
			} else {
				notifications.showSnackbar( "Unable to calculate, try again later", "error" );
			}
		} finally {
			action( () => {
				store.processing = false;
			} )()
		}
	};

	useEffect( () => {
		( async () => {
			action( () => {
				store.loading = true;
			} )()
			try {
				const { data } = await axios( {
					method: 'get',
					url: 'https://api.nbp.pl/api/exchangerates/rates/a/eur/',
				} );

				action( () => {
					store.plnToEur = data.rates[0].mid;
					store.loaded = true;
				} )()

				const tender = ( await axios( {
					method: 'post',
					url: '/api/v1/tender/details',
					data: {
						id,
					}
				} ) ).data.details
				action( () => {
					store.tender = new Tender( tender );
				} )()
			} catch (e) {
				notifications.showSnackbar( "Unable to load exchange rates", "error" );
			} finally {
				action( () => {
					store.loading = false;
				} )()
			}
		})().then();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [] )

	return (
		<BaseScreen
			icon={CalcIcon}
			title="New calculation"
			loading={store.loading}
			loaded={store.loaded}
			breadCrumbs={[
				{
					link: "/",
					title: "Tenders",
				},
				{
					link: `/tenders/${id}`,
					title: store.tender ? store.tender.name : id,
				},
				{
					link: `/tenders/${id}/calculations`,
					title: "Calculations",
				},
				{
					link: window.location.pathname,
					title: "New calculation",
				},
			]}
		>
			{
				store.plnToEur && (
					<CalcForm
						onSubmit={onSubmit}
						plnToEur={store.plnToEur}
						processing={store.processing}
						result={store.result}
						currentCalculation={store.currentCalculation}
						resultCurrency={store.resultCurrency}
						tender={store.tender}
						readonly={false}
					/>
				)
			}
		</BaseScreen>
	);
} );

export default Calc;

import React, {FunctionComponent} from 'react';
import {observer} from "mobx-react";
import * as _ from 'lodash';
import {BrowserRouter as Router, Redirect, Route, Switch} from 'react-router-dom';
import LoginScreen from "./containers/screens/public/login";
import CreatePwdScreen from "./containers/screens/public/create_pwd";
import ResetPwdScreen from "./containers/screens/public/reset_pwd";
import CreateNewPwdScreen from "./containers/screens/public/create_new_pwd";
import CreateNewOtpScreen from "./containers/screens/public/create_new_otp";
import NotFoundScreen from "./components/public/not_found";
import AppScreen from "./components/layout/app_screen";
import {UserManager} from "./containers/contexts/user_ctx";
import './index.css';


const sharedRoutes = () =>
	(
		<Switch>
			<Route path="/create-password/:username/:token" component={CreatePwdScreen}/>
			<Route path="/reset-password" component={ResetPwdScreen}/>
			<Route path="/create-new-password/:token" component={CreateNewPwdScreen}/>
			<Route path="/create-new-otp/:username/:token" component={CreateNewOtpScreen}/>
			<Redirect path="*" to="/404" />
		</Switch>
	);

const renderPrivateRoutes = () => {
	return (
		<UserManager>
			<Switch>
				<Route exact={true} path="/404" component={NotFoundScreen} />
				<Route path="/" component={AppScreen}/>
				{sharedRoutes()}
			</Switch>
		</UserManager>
	);
};

const renderPublicRoutes = () =>
	(
		<Switch>
			<Route path="/404" component={NotFoundScreen} />
			<Route exact={true} path="/" component={LoginScreen}/>
			{sharedRoutes()}
		</Switch>
	);

const App: FunctionComponent<{}> = observer( () => {
	return (
		<Router>
			{!_.isEmpty( ( window as any ).config.user ) ? renderPrivateRoutes() : renderPublicRoutes()}
		</Router>
	);
} );

export default App;

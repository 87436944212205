import * as React from 'react';
import {Component} from 'react';
import {observer} from 'mobx-react';
import styled, {keyframes} from 'styled-components';
import ErrorIcon from '@material-ui/icons/HighlightOff';
import Typography from '@material-ui/core/Typography';

const fadeIn = keyframes`
	from { opacity: 0 }
	33% { opacity: 0 }
	100% { opacity: 1 }
`;

export const ErrorWrapper = styled.div`
	width: 100%;
	text-align: center;
	animation: ${fadeIn} 1.5s;
`;


export interface ScreenErrorProps {
}

@observer
export class ScreenError extends Component<ScreenErrorProps, {}> {

	public render() {
		return (
			<ErrorWrapper>
				<ErrorIcon style={{ color: '#ccc', width: '40%', height: '40%' }}/><br/>
				<Typography variant="h5" gutterBottom={true}>
					{this.props.children}
				</Typography>

			</ErrorWrapper>
		);
	}

}

import * as React from 'react';
import {FunctionComponent} from 'react';

import {Box, Button, Link, TextField} from '@material-ui/core';
import {Link as A} from 'react-router-dom';
import {passwordRegex} from "../../utils/regexes";
import Typography from '@material-ui/core/Typography';
import {observer} from "mobx-react";
import {useForm} from "react-hook-form";

type Props = {
	token: string;
}


export const CreatePasswordForm: FunctionComponent<Props> = observer( ( props: Props ) => {
	const { register } = useForm();

	return (
		<div className="LandingPanelForm">
			<form method="post" action="/api/v1/auth/create-new-password">
				<div className="LandingPanelInput">
					<TextField
						inputRef={register()}
						type="password"
						name="password"
						placeholder="New password"
						label="New password"
						autoFocus={true}
						variant="outlined"
						fullWidth={true}
						required={true}
						inputProps={{minLength: 8, maxLength: 30, pattern: passwordRegex.source}}
					/>
				</div>

				<div className="LandingPanelInput">
					<TextField
						inputRef={register()}
						type="password"
						name="confirmed_password"
						placeholder="Confirm new password"
						label="Confirm new password"
						variant="outlined"
						fullWidth={true}
						required={true}
						inputProps={{minLength: 8, maxLength: 30, pattern: passwordRegex.source}}
					/>
				</div>

				<input type="hidden" name="token" value={props.token} />

				<div className="LandingPanelInput">
					<Button type="submit" variant="contained" color="secondary" fullWidth={true}>
						Create new password
					</Button>
				</div>

				<Box>
					<Typography variant="body2">
						<Link component={A} to="/">
							Back to login page
						</Link>
					</Typography>
				</Box>
			</form>
		</div>
	);
} );

export default CreatePasswordForm;
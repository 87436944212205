import * as React from 'react';
import {Component} from 'react';
import Wrapper from '../../../components/public/wrapper';
import CreateNewPasswordForm from '../../../components/public/create_new_password_form';
import {observer} from 'mobx-react';
import {Box} from "@material-ui/core";

@observer
class CreateNewPwd extends Component<any, any> {
	render() {

		return (
			<Wrapper>
				<Box className="LandingPanelDescription">Create new password</Box>

				<CreateNewPasswordForm token={this.props.match.params.token} />
			</Wrapper>
		);
	}
}

export default CreateNewPwd;

import * as _ from 'lodash';
import * as React from 'react';
import {Component} from 'react';
import {observer} from 'mobx-react';
import {action, makeObservable, observable} from "mobx";
import axios from 'axios';
import {BaseScreen} from "../../components/base_screen";
import {SnackbarsContext} from "../contexts/snackbar_ctx";
import Calculation from "../../models/calculation";
import CalculationsTable from "../../components/calcs_table";
import AddIcon from "@material-ui/icons/Add";
import Tender from "../../models/tender";
import CalcIcon from "@material-ui/icons/Tune";
import TendersTable from "../../components/tenders_table";


export type Order = 'asc' | 'desc';

@observer
class Calculations extends Component<any, any> {
	static contextType = SnackbarsContext; // To use multiple context use func component

	calculations: Calculation[] = [];
	tender?: Tender = undefined;
	loading: boolean = false;
	count: number = 0;
	loaded: boolean = true;

	orderBy: string =  'id';
	order: Order = 'asc';
	page = 0;
	rows = 10;

	constructor( props: any, context: any ) {
		super( props, context );

		makeObservable(this, {
			calculations: observable,
			tender: observable,
			loading: observable,
			loaded: observable,
		});
	}

	public async UNSAFE_componentWillMount() {
		await this.loadCalcs();
	}

	public render() {
		return (
			<BaseScreen
				icon={CalcIcon}
				title="Calculations"
				loading={this.loading}
				loaded={this.loaded}
				actionIcon={AddIcon}
				onAction={this.create}
				emptyListMessage={this.loaded && !this.calculations.length ? 'Calculations list is empty' : undefined}
				breadCrumbs={[
					{
						link: "/",
						title: "Tenders",
					},
					{
						link: `/tenders/${this.props.match.params.id}`,
						title: this.tender ? this.tender.name : this.props.match.params.id,
					},
					{
						link: window.location.pathname,
						title: "Calculations",
					},
				]}
			>
				{this.calculations && this.calculations.length && <CalculationsTable
					calculations={this.calculations}
					count={this.count}
                    rows={this.rows}
					changePage={this.changePage}
                    changeRowsPerPage={this.changeRowsPerPage}
					changeOrder={this.changeOrder}
					page={this.page}
					orderBy={this.orderBy}
					order={this.order}
				/>}
			</BaseScreen>
		);
	}

	private create = () => {
		this.props.history.push( `/tenders/${this.props.match.params.id}/calculations/create` );
	};

	private changePage = (event: unknown, newPage: number) => {
		this.page = newPage;
		this.loadCalcs().then();
	};

	private changeRowsPerPage = (event: any) => {
		this.rows = Number(event.target.value);
		this.page = 0;
		this.loadCalcs().then();
	};

	private changeOrder = (cellId: any) => {
		if( this.orderBy === cellId ) {
			this.order = this.order === 'asc' ? 'desc' : 'asc';
		} else {
			this.orderBy = cellId;
			this.order = 'asc'
		}

		this.loadCalcs().then();
	};

	private loadCalcs = async ( page = 0, orderBy = 'id', order = 'desc' ) => {
		try {
			action( () => {
				this.loading = true;
			} )()

			const { data } = await axios( {
				method: 'post',
				url: '/api/v1/calculate/history',
				data: {
					page: this.page,
					orderBy: this.orderBy,
					order: this.order,
					pageSize: this.rows,
					tenderId: this.props.match.params.id,
				}
			} );

			if( data.success ) {
				action( () => {
					this.calculations = _.map( data.calculations, d => new Calculation( d ) );
					this.count = data.count;
					this.loaded = true;
				} )()
			} else {
				throw new Error( "Invalid response" );
			}

			const tender = ( await axios( {
				method: 'post',
				url: '/api/v1/tender/details',
				data: {
					id: this.props.match.params.id,
				}
			} ) ).data.details
			action( () => {
				this.tender = new Tender( tender );
			} )()

		} catch( err ) {
			console.error( err );
			this.context.showSnackbar( "Can not load calculations", "error" );
		} finally {
			action( () => {
				this.loading = false;
			} )()
		}
	}
}

export default Calculations;

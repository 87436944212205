import * as React from 'react';
import {FunctionComponent, useContext} from 'react';
import {observer} from 'mobx-react';
import {Box, Typography} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Calculation from "../models/calculation";
import {UserContext} from "../containers/contexts/user_ctx";
import {formatPrice} from "../utils/format_price";


interface CalcFormProps {
	result?: number;
	currentCalculation?: Calculation;
	resultCurrency?: string;
	calc?: Calculation;
}


export const CalcResultsGrid: FunctionComponent<CalcFormProps> = observer( ( props: CalcFormProps ) => {
	const {user} = useContext(UserContext)

	const totalPrice = (props.calc || props.currentCalculation)?.priceDetails?.totalPrice! || props.result || props.calc?.price!;
	const installationCost = (props.calc || props.currentCalculation)?.priceDetails?.installationCost!

	const margin = totalPrice - installationCost;
	// const marginPercentage = margin / (props.calc || props.currentCalculation)?.priceDetails?.totalPrice!;
	const marginPercentage = ( totalPrice - installationCost ) / totalPrice * 100;

	return <>
		{
			(props.result || props.calc) && <>
				<Grid item={true} xs={12} md={3}>
					<Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
						<Typography variant="subtitle1" color="textSecondary">
							Price (without tax):
						</Typography>

						<Typography variant="h5" style={{fontWeight: "bold"}}>
							{formatPrice(Math.ceil(totalPrice))} {props.resultCurrency || props.calc?.params?.output_currency}
						</Typography>
						{
							(props.currentCalculation || props.calc?.priceDetails) && user.isAdmin && <>
								&nbsp;

								<Typography variant="subtitle1" color="textSecondary">
									Margin:
								</Typography>

								<Typography variant="h5" style={{fontWeight: "bold"}}>
									{formatPrice(margin)} {props.resultCurrency || (props.calc || props.currentCalculation)?.params?.output_currency}
								</Typography>

								&nbsp;

								<Typography variant="subtitle1" color="textSecondary">
									Aws price:
								</Typography>

								<Typography variant="h5">
									{formatPrice((props.calc || props.currentCalculation)?.priceDetails?.partial?.awsPrice || 0)} {props.resultCurrency || (props.calc || props.currentCalculation)?.params?.output_currency}
								</Typography>

								&nbsp;

								<Typography variant="subtitle1" color="textSecondary">
									Hidden support price:
								</Typography>

								<Typography variant="h5">
									{formatPrice((props.calc || props.currentCalculation)?.priceDetails?.partial?.hiddenSupportPrice || 0)} {props.resultCurrency || (props.calc || props.currentCalculation)?.params?.output_currency}
								</Typography>

								&nbsp;

								<Typography variant="subtitle1" color="textSecondary">
									Transmission price:
								</Typography>

								<Typography variant="h5">
									{formatPrice((props.calc || props.currentCalculation)?.priceDetails?.partial?.transmissionPrice || 0)} {props.resultCurrency || (props.calc || props.currentCalculation)?.params?.output_currency}
								</Typography>

								&nbsp;

								<Typography variant="subtitle1" color="textSecondary">
									Visible support price:
								</Typography>

								<Typography variant="h5">
									{formatPrice((props.calc || props.currentCalculation)?.priceDetails?.partial?.visibleSupportPrice || 0)} {props.resultCurrency || (props.calc || props.currentCalculation)?.params?.output_currency}
								</Typography>

								&nbsp;

								<Typography variant="subtitle1" color="textSecondary">
									Embedded software licence:
								</Typography>

								<Typography variant="h5">
									{formatPrice((props.calc || props.currentCalculation)?.priceDetails?.partial?.oneTimeLicenceForInvestment || 0)} {props.resultCurrency || (props.calc || props.currentCalculation)?.params?.output_currency}
								</Typography>

								&nbsp;
							</>
						}
					</Box>
				</Grid>
				<Grid item={true} xs={12} md={3}>
					<Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
						{
							(props.currentCalculation || props.calc?.priceDetails) && user.isAdmin && <>
								<Typography variant="subtitle1" color="textSecondary">
									Installation cost:
								</Typography>

								<Typography variant="h5" style={{fontWeight: "bold"}}>
									{formatPrice(installationCost || 0)} {props.resultCurrency || props.calc?.params?.output_currency}
								</Typography>

								&nbsp;
								<Typography variant="subtitle1" color="textSecondary">
									Margin %:
								</Typography>

								<Typography variant="h5" style={{fontWeight: "bold"}}>
									{formatPrice(marginPercentage)} %
								</Typography>

								&nbsp;

								<Typography variant="subtitle1" color="textSecondary">
									Aws cost:
								</Typography>

								<Typography variant="h5">
									{formatPrice((props.calc || props.currentCalculation)?.priceDetails?.partial?.awsCost || 0)} {props.resultCurrency || (props.calc || props.currentCalculation)?.params?.output_currency}
								</Typography>

								&nbsp;

								<Typography variant="subtitle1" color="textSecondary">
									Hidden support cost:
								</Typography>

								<Typography variant="h5">
									{formatPrice((props.calc || props.currentCalculation)?.priceDetails?.partial?.hiddenSupportCost || 0)} {props.resultCurrency || (props.calc || props.currentCalculation)?.params?.output_currency}
								</Typography>

								&nbsp;

								<Typography variant="subtitle1" color="textSecondary">
									Transmission cost:
								</Typography>

								<Typography variant="h5">
									{formatPrice((props.calc || props.currentCalculation)?.priceDetails?.partial?.totalTransmissionCost || 0)} {props.resultCurrency || (props.calc || props.currentCalculation)?.params?.output_currency}
								</Typography>

								&nbsp;

								<Typography variant="subtitle1" color="textSecondary">
									Visible support cost:
								</Typography>

								<Typography variant="h5">
									{formatPrice((props.calc || props.currentCalculation)?.priceDetails?.partial?.visibleSupportCost || 0)} {props.resultCurrency || (props.calc || props.currentCalculation)?.params?.output_currency}
								</Typography>
							</>
						}
					</Box>
				</Grid>
			</>
		}
	</>
} );

export default CalcResultsGrid;

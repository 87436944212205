import React, {FunctionComponent, useCallback, useEffect, useState} from "react";
import {observer} from "mobx-react";
import {Snackbar} from "@material-ui/core";
import MuiAlert from '@material-ui/lab/Alert';
import {AlertProps, Color} from "@material-ui/lab/Alert/Alert";
import {action, observable} from "mobx";
import * as _ from "lodash";

function Alert( props: AlertProps ) {
	return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export interface Snackbars {
	showSnackbar: (message: string, severity?: Color ) => void;
	open: boolean;
	message: string;
	severity: Color;
	msgsQueue: { message: string; severity: Color }[];
	processing: boolean;
	onClose?: (...args: any[]) => void;
}

export const SnackbarsContext = React.createContext({} as Snackbars);

// TODO: Add messages queue
// TODO: Hide open mesg and severity from interface
export const SnackbarManager: FunctionComponent<{}> = observer( (props ) => {
	const [store] = useState(() =>
		observable( {
			showSnackbar: _.noop,
			open: false,
			message: "",
			severity: "success",
			msgsQueue: [],
			processing: false,
			onClose: undefined,
		} as Snackbars )
	);

	const processMsg = action( async () => {
		if( !store.processing ) {
			action( () => {
				store.processing = true;
			} )()

			// eslint-disable-next-line
			for( let msg: any; msg = store.msgsQueue.shift(); ) {
				action( () => {
					store.open = true;
					store.message = msg.message;
					store.severity = msg.severity;
				} )()
				await new Promise( resolve => {
					action( () => {
						store.onClose = resolve;
					} )()
					setTimeout( resolve, 5000 );
				} );
			}
			action( () => {
				store.processing = false;
			} )()
		}
	} );

	const handleClose = action( () => {
		action( () => {
			store.open = false;
			if( store.onClose ) {
				store.onClose();
			}
		} )()
		processMsg().then();
	} );

	store.showSnackbar = useCallback( ( (message: string, severity: Color = "success" ) => {
		action( () => {
			store.msgsQueue.push( { message, severity } );
		} )()
		processMsg().then();
	} ), [store, processMsg] );

	useEffect( () => {
		const msgs: any[] = _.union(
			_.map( ( window as any ).config.messages.error, m => [m, 'error'] ),
			_.map( ( window as any ).config.messages.success, m => [m, 'success'] ),
			_.map( ( window as any ).config.messages.info, m => [m, 'info'] ),
		);
		if ( _.isArray( msgs ) && msgs.length > 0 ) {
			const showInitialError = () => {
				const m: any[] = msgs.shift();
				store.showSnackbar( m[0], m[1] );
				if ( msgs.length > 0 ) {
					setTimeout( () => {
						showInitialError();
					}, 5000 );
				}
			};
			showInitialError();
		}
	}, [] )

	return(
		<SnackbarsContext.Provider value={store}>
			{props.children}
			<Snackbar open={store.open} autoHideDuration={5000} onClose={handleClose}>
				<Alert onClose={handleClose} severity={store.severity}>
					{store.message}
				</Alert>
			</Snackbar>
		</SnackbarsContext.Provider>
	)
} );

import * as React from 'react';
import {FunctionComponent, SyntheticEvent} from 'react';
import {Button} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import {FormDialogProps} from "../form_dialog_wrapper";
import {observer} from "mobx-react";
import {useForm} from "react-hook-form";
import {AccessLevel, humanAccessLevel} from "../../models/user";
import {RadioCard} from "../radio_card";
import {Alert} from "@material-ui/lab";
import {Spacer} from "../spacer";


export const AccessLevelForm: FunctionComponent<FormDialogProps> = observer( ( props: FormDialogProps ) => {
	const { register, handleSubmit, setValue, watch } = useForm( {
		defaultValues: {
			accessLevel: `${props.data.accessLevel}`,
		}
	} );

	const onSubmit = async ( e: SyntheticEvent ) => {
		await handleSubmit(async ( data: any ) => {
			await props.onSubmit( data.accessLevel );
			e.preventDefault();
		})(e);
	};

	return (
		<form onSubmit={onSubmit}>

			<Grid container={true} spacing={3} style={{marginTop: -12, marginBottom: 0}}>
				<Grid item={true} xs={4}>
					<RadioCard
						name="accessLevel"
						value={`${AccessLevel.normal}`}
						register={register}
						setValue={setValue}
						watch={watch}
						title={humanAccessLevel[AccessLevel.normal]}
						description={`Access only for own tenders and all calculations which belongs to them`}
						minHeight={180}
					/>
				</Grid>
				<Grid item={true} xs={4}>
					<RadioCard
						name="accessLevel"
						value={`${AccessLevel.moderator}`}
						register={register}
						setValue={setValue}
						watch={watch}
						title={humanAccessLevel[AccessLevel.moderator]}
						description={`Access for all tenders and calculations created by any user`}
						minHeight={180}
					/>
				</Grid>

				<Grid item={true} xs={4}>
					<RadioCard
						name="accessLevel"
						value={`${AccessLevel.admin}`}
						register={register}
						setValue={setValue}
						watch={watch}
						title={humanAccessLevel[AccessLevel.admin]}
						description={`Full access and email notifications for every calculation`}
						minHeight={180}
					/>
				</Grid>


				<Grid item={true} xs={12}>
					<Alert severity="info">Changes will be effective after user relogin</Alert>
				</Grid>

				<Grid item={true} xs={12} style={{ textAlign: 'right' }}>
					<Button onClick={props.onCancel} disabled={props.processing}>
						Cancel
					</Button>
					<Button
						type="submit"
						color="secondary"
						disabled={props.processing}
					>
						Ok
					</Button>
				</Grid>
			</Grid>
		</form>
	)
} );

export default AccessLevelForm;
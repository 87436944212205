import React, {FunctionComponent, useCallback, useState} from "react";
import {observer} from "mobx-react";
import {action, observable} from "mobx";
import * as _ from "lodash";

export interface Fullscreen {
	fullscreen: boolean;
	open: () => void;
	close: () => void;
}

export const FullscreenContext = React.createContext({} as Fullscreen);

export const FullscreenManager: FunctionComponent<{}> = observer( (props ) => {
	const [store] = useState(() =>
		observable( {
			fullscreen: false,
			open: _.noop,
			close: _.noop,
		} as Fullscreen )
	);

	store.open = useCallback( ( () => {
		action( () => {
			store.fullscreen = true;
		} )()
	} ), [store] );

	store.close = useCallback( ( () => {
		action( () => {
			store.fullscreen = false;
		} )()
	} ), [store] );

	return(
		<FullscreenContext.Provider value={store}>
			{props.children}
		</FullscreenContext.Provider>
	)
} );

import * as React from 'react';
import {Component} from 'react';
import {observer} from 'mobx-react';

import './wrapper.css';

import authBgImg from '../../static/bg.jpg';
import logoImg from '../../static/logo-light.svg';

@observer
class AuthWrapper extends Component<any, any> {

	render() {
		return (
			<div className="LandingPanelBg" style={{ backgroundImage: `url('${ authBgImg }') ` }}>
				<div className="LandingPanel">
					<div className="LandingPanelHeader">
						<div
							className="LandingPanelLogo"
							style={{ backgroundImage: `url('${ logoImg }')` }}
						/>
					</div>
					{this.props.children}
				</div>
			</div>
		);
	}
}

export default AuthWrapper;

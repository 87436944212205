import React, {FunctionComponent} from "react";
import {observer} from "mobx-react";


export type Props ={
	width?: number,
	height?: number,
	className?: string,
}

export const Spacer: FunctionComponent<Props> = observer( ( props ) => {
	return <div style={{width: props.width || 1, height: props.height || 1, flexShrink: 0}} className={props.className} />
} );

import * as React from 'react';
import {FunctionComponent} from 'react';
import {observer} from 'mobx-react';
import {
	Box,
	createStyles,
	makeStyles,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TablePagination,
	TableRow,
	TableSortLabel,
	Theme,
	Typography
} from '@material-ui/core';
import moment from 'moment';
import {TableWrapper} from "./html_components";
import Tender from "../models/tender";
import {useHistory} from "react-router-dom";
import {Order} from "../containers/screens/calculations";
import {countries} from "countries-list";
import {FilterLabel} from "./table_filters/filter_label";
import {FilterText} from "./table_filters/filter_text";
import {FilterCountry} from "./table_filters/filter_country";
import {SearchFilters} from "../containers/screens/tenders_list";


const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		visuallyHidden: {
			border: 0,
			clip: 'rect(0 0 0 0)',
			height: 1,
			margin: -1,
			overflow: 'hidden',
			padding: 0,
			position: 'absolute',
			top: 20,
			width: 1,
		},
		limitedRow: {
			maxWidth: 120,
			whiteSpace: 'nowrap',
			overflow: 'hidden',
			textOverflow: 'ellipsis',
		}
	}),
);


type HeadCells = {
	id: string;
	label: string;
	sortable: boolean;
	filter: any;
}

const headCells: HeadCells[] = [
	{ id: 'id', label: 'ID', sortable: true, filter: FilterLabel },
	{ id: 'name', label: 'Name', sortable: true, filter: FilterText },
	{ id: 'locationCountry', label: 'Country', sortable: true, filter: FilterCountry },
	{ id: 'user', label: 'By', sortable: false, filter: FilterText },
	{ id: 'createdAt', label: 'Created', sortable: true, filter: null },
	{ id: 'crm', label: 'CRM number', sortable: true, filter: FilterText },
	// { id: 'trader', label: 'Trader', sortable: true, filter: FilterText },
	// { id: 'client', label: 'Client name', sortable: true, filter: FilterText },
	{ id: 'llu', label: 'LLU person', sortable: true, filter: FilterText },
	{ id: 'lastPrice', label: 'Last price', sortable: true, filter: null },
	{ id: 'state', label: 'State', sortable: true, filter: null },
];

interface TendersTableProps {
	tenders: Tender[];
	count: number;
	rows: number;
	changePage: (event: unknown, newPage: number) => void;
	changeRowsPerPage: (event: unknown) => void;
	changeOrder: (cellId: any) => void;
	onFilter: (name: string, value: string) => void;
	page: number;
	orderBy: string;
	order: Order;
	filters: SearchFilters;
}

const formatPrice = ( price: number ) => price.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$& ');

export const TendersTable: FunctionComponent<TendersTableProps> = observer( ( props: TendersTableProps ) => {
	const classes = useStyles();
	const history = useHistory();

	const createChangeOrderHandler = (property: any) => (event: React.MouseEvent<unknown>) => {
		props.changeOrder(property);
	};

	return <>
		<TableWrapper>
			<Table>
				<TableHead>
					<TableRow>
						{
							headCells.map( cell => (
								<TableCell
									sortDirection={props.orderBy === cell.id ? props.order : false}
									style={{fontWeight: 'bold'}}
									key={cell.id}
								>
									{/*<Box display="flex" alignItems="center">*/}
									{/*	<IconButton aria-label="delete" size="small" style={{marginRight: 4, marginLeft: -8, marginTop: -2}}>*/}
									{/*		<FilterListIcon fontSize="inherit" />*/}
									{/*	</IconButton>*/}
										{
											cell.sortable ? (
												<TableSortLabel
													active={props.orderBy === cell.id}
													direction={props.orderBy === cell.id ? props.order || 'desc'  : 'asc'}
													onClick={createChangeOrderHandler(cell.id)}
												>
													{cell.label}
													{props.orderBy === cell.id ? (
														<span className={classes.visuallyHidden}>
															{props.order === 'asc' ? 'sorted descending' : 'sorted ascending'}
														</span>
													) : null}
												</TableSortLabel>
											) : cell.label
										}
									{/*</Box>*/}
								</TableCell>
							) )
						}
					</TableRow>
				</TableHead>
				<TableBody>
					<TableRow>
						{
							headCells.map( cell => (
								<TableCell key={cell.id}>
									{
										cell.filter ?
											<cell.filter name={cell.id} label={cell.label} onChange={props.onFilter} defaultValue={props.filters[cell.id]} />
										:
											<Typography variant="caption" color="textSecondary">-</Typography>
									}
								</TableCell>
							) )
						}
					</TableRow>
					{props.tenders.map(tender => {
						return (
							<TableRow onClick={ () => { history.push(`/tenders/${tender.id}`) } } key={tender.id} hover={true} style={{cursor: "pointer"}}>
								<TableCell>
									{tender.id}
								</TableCell>
								<TableCell className={classes.limitedRow}>
									{tender.name}
								</TableCell>
								<TableCell>
									{countries[tender.locationCountry || ""]?.emoji} {countries[tender.locationCountry || ""]?.name}
								</TableCell>
								<TableCell className={classes.limitedRow}>
									{tender.user?.username}
								</TableCell>
								<TableCell>
									{tender.createdAt ? moment(tender.createdAt).fromNow() : '-'}
								</TableCell>
								<TableCell>
									{tender.crmLugProjectNo}
								</TableCell>
								{/*<TableCell className={classes.limitedRow}>*/}
								{/*	{tender.commerceTrader}*/}
								{/*</TableCell>*/}
								{/*<TableCell>*/}
								{/*	{tender.clientName}*/}
								{/*</TableCell>*/}
								<TableCell className={classes.limitedRow}>
									{tender.lluContactPerson}
								</TableCell>
								<TableCell>
									{tender.calculation ? `${formatPrice(tender.calculation.price || 0)} ${tender.calculation.params?.output_currency}` : '-'}
								</TableCell>
								<TableCell style={{color: tender.state === "win" ? "#34a853" : ( tender.state === "lost" ? "#e60012" : "inherit" )}}>
									{tender.state}
								</TableCell>
							</TableRow>
						);
					})}
				</TableBody>
			</Table>
		</TableWrapper>

		<Box display="flex" justifyContent="center" alignItems="center">
			<Box flex={1} />
			<Box display="flex" justifyContent="center" alignItems="center">
				{/*<Button color="primary">Filter</Button>*/}
				{/*<Button color="primary" disabled={true}>Select columns</Button>*/}
			</Box>
			<TablePagination
				component="div"
				count={props.count}
				rowsPerPageOptions={[10, 25, 50,100]}
				rowsPerPage={props.rows}
				page={props.page}
				onChangePage={props.changePage}
				onChangeRowsPerPage={props.changeRowsPerPage}
			/>
		</Box>
	</>
} );

export default TendersTable;
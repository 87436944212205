import {action, makeObservable, observable} from "mobx";
import {BaseModel} from "../utils/base_model";
import User from "./user";
import Calculation from "./calculation";

export default class Tender extends BaseModel {
	id?: number;
	name?: string;
	state?: string;
	chanceToWin?: string;
	chanceToWinDescription?: string;
	crmLugProjectNo?: string;
	lluContactPerson?: string;
	clientName?: string;
	clientContactPerson?: string;
	clientTel?: string;
	clientMail?: string;
	commerceTeamName?: string;
	commerceTrader?: string;
	commerceTel?: string;
	commerceMail?: string;
	locationCountry?: string;
	locationCity?: string;
	locationDistrictOrStreet?: string;
	locationBuildingsType?: string;
	locationTerrainType?: string;
	deadlineForAskingQuestions?: Date;
	deadlineForOfferPresentationToClient?: Date;
	deadlineForDecision?: Date;
	deadlineForDelivery?: Date;
	deadlineForAcceptanceDateForInstalledSystem?: Date;
	price?: number;
	user?: User;
	calculation?: Calculation;

	createdAt?: Date;

	constructor( json: any ) {
		super();

		makeObservable(this, {
			id: observable,
			name: observable,
			state: observable,
			chanceToWin: observable,
			chanceToWinDescription: observable,
			crmLugProjectNo: observable,
			lluContactPerson: observable,
			clientName: observable,
			clientContactPerson: observable,
			clientTel: observable,
			clientMail: observable,
			commerceTeamName: observable,
			commerceTrader: observable,
			commerceTel: observable,
			commerceMail: observable,
			locationCountry: observable,
			locationCity: observable,
			locationDistrictOrStreet: observable,
			locationBuildingsType: observable,
			locationTerrainType: observable,
			deadlineForAskingQuestions: observable,
			deadlineForOfferPresentationToClient: observable,
			deadlineForDecision: observable,
			deadlineForDelivery: observable,
			deadlineForAcceptanceDateForInstalledSystem: observable,
			user: observable,
			calculation: observable,
			createdAt: observable,
		});

		this.deserialize( json );
	}

	deserialize = action( ( json: any ) => {
		this.deserializeFields( json, [
			'id',
			'name',
			'state',
			'chanceToWin',
			'chanceToWinDescription',
			'crmLugProjectNo',
			'lluContactPerson',
			'clientName',
			'clientContactPerson',
			'clientTel',
			'clientMail',
			'commerceTeamName',
			'commerceTrader',
			'commerceTel',
			'commerceMail',
			'locationCountry',
			'locationCity',
			'locationDistrictOrStreet',
			'locationBuildingsType',
			'locationTerrainType',
			'deadlineForAskingQuestions',
			'deadlineForSubmittingTheOffer',
			'deadlineForAwardTheTender',
			'deadlineForDelivery',
			'deadlineForReceipt',
			'createdAt',
		] );

		if( json.createdAt ) {
			this.createdAt = new Date( json.createdAt );
		}

		if( json.deadlineForAskingQuestions ) {
			this.deadlineForAskingQuestions = new Date( json.deadlineForAskingQuestions );
		}

		if( json.deadlineForOfferPresentationToClient ) {
			this.deadlineForOfferPresentationToClient = new Date( json.deadlineForOfferPresentationToClient );
		}

		if( json.deadlineForDecision ) {
			this.deadlineForDecision = new Date( json.deadlineForDecision );
		}

		if( json.deadlineForDelivery ) {
			this.deadlineForDelivery = new Date( json.deadlineForDelivery );
		}

		if( json.deadlineForAcceptanceDateForInstalledSystem ) {
			this.deadlineForAcceptanceDateForInstalledSystem = new Date( json.deadlineForAcceptanceDateForInstalledSystem );
		}

		if( json.user ) {
			this.user = new User( json.user );
		}

		if( json.calculation ) {
			this.calculation = new Calculation( json.calculation );
		}
	} )
}

import React, {FunctionComponent} from "react";
import {observer} from "mobx-react";
import {TextField} from "@material-ui/core";


export type Props = {
	register: any;
	name: string;
	label: string;
	required: boolean;
	disabled?: boolean;
	defaultValue?: string;
	multiline?: boolean;
	rows?: number;
}

export const TextInput: FunctionComponent<Props> = observer( ( props ) => {
	return <>
		<TextField
			inputRef={!props.disabled ? props.register({}) : undefined}
			variant="outlined"
			type="text"
			name={!props.disabled ? props.name : undefined}
			placeholder={props.label}
			label={props.label}
			fullWidth={true}
			required={props.required}
			disabled={props.disabled}
			defaultValue={props.defaultValue || ""}
			multiline={props.multiline}
			rows={props.rows}
		/>
		{
			props.disabled && (
				<input type="hidden" ref={props.register({})} name={props.name} value={props.defaultValue || "0"} />
			)
		}
	</>
} );

import * as React from 'react';
import {FunctionComponent} from 'react';
import {observer} from 'mobx-react';
import {
	createStyles,
	makeStyles,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TablePagination,
	TableRow,
	TableSortLabel,
	Theme
} from '@material-ui/core';
import moment from 'moment';
import {TableWrapper} from "./html_components";
import Calculation, {CalculationState} from "../models/calculation";
import {useHistory, useParams} from "react-router-dom";
import {Order} from "../containers/screens/calculations";


const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		visuallyHidden: {
			border: 0,
			clip: 'rect(0 0 0 0)',
			height: 1,
			margin: -1,
			overflow: 'hidden',
			padding: 0,
			position: 'absolute',
			top: 20,
			width: 1,
		},
	}),
);

const formatPrice = ( price: number ) => price.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$& ');

type HeadCells = {
	id: string;
	label: string;
	sortable: boolean;
}

const headCells: HeadCells[] = [
	{ id: 'id', label: 'ID', sortable: true },
	{ id: 'name', label: 'Name', sortable: true },
	{ id: 'price', label: 'Price', sortable: true },
	{ id: 'user', label: 'By', sortable: false },
	{ id: 'creationDate', label: 'Creation date', sortable: true },
	{ id: 'state', label: 'State', sortable: false },
];

interface CalculationsTableProps {
	calculations: Calculation[];
	count: number;
	rows: number;
	changePage: (event: unknown, newPage: number) => void;
	changeRowsPerPage: (event: unknown) => void;
	changeOrder: (cellId: any) => void;
	page: number;
	orderBy: string;
	order: Order;
}

export const CalculationsTable: FunctionComponent<CalculationsTableProps> = observer( ( props: CalculationsTableProps ) => {
	const classes = useStyles();
	const history = useHistory();
	const { id } = useParams() as any;

	const createChangeOrderHandler = (property: any) => (event: React.MouseEvent<unknown>) => {
		props.changeOrder(property);
	};

	return <>
		<TableWrapper>
			<Table>
				<TableHead>
					<TableRow>
						{
							headCells.map( cell => (
								<TableCell
									sortDirection={props.orderBy === cell.id ? props.order : false}
									style={{fontWeight: 'bold'}}
									key={cell.id}
								>
									{
										cell.sortable ? (
											<TableSortLabel
												active={props.orderBy === cell.id}
												direction={props.orderBy === cell.id ? props.order || 'desc'  : 'asc'}
												onClick={createChangeOrderHandler(cell.id)}
											>
												{cell.label}
												{props.orderBy === cell.id ? (
													<span className={classes.visuallyHidden}>
												{props.order === 'asc' ? 'sorted descending' : 'sorted ascending'}
											</span>
												) : null}
											</TableSortLabel>
										) : cell.label
									}
								</TableCell>
							) )
						}
					</TableRow>
				</TableHead>
				<TableBody>
					{props.calculations.map(calc => {
						return (
							<TableRow onClick={ () => { history.push(`/tenders/${id}/calculations/${calc.id}/details/`) } } key={calc.id} hover={true} style={{cursor: "pointer"}}>
								<TableCell>
									{calc.id}
								</TableCell>
								<TableCell>
									{calc.name}
								</TableCell>
								<TableCell>
									{formatPrice(calc.price || 0)} {calc.params.output_currency}
								</TableCell>
								<TableCell>
									{calc.user?.username} ({calc.user?.email})
								</TableCell>
								<TableCell>
									{calc?.creationDate ? moment(calc?.creationDate).fromNow() : '-'}
								</TableCell>
								<TableCell style={{color: calc.state === CalculationState.active ? "#34a853" : "inherit"}}>
									{calc.state}
								</TableCell>
							</TableRow>
						);
					})}
				</TableBody>
			</Table>
		</TableWrapper>

		<TablePagination
			component="div"
			count={props.count}
			rowsPerPageOptions={[10, 25, 50,100]}
			rowsPerPage={props.rows}
			page={props.page}
			onChangePage={props.changePage}
			onChangeRowsPerPage={props.changeRowsPerPage}
		/>
	</>
} );

export default CalculationsTable;
import * as React from 'react';
import {Component} from 'react';
import {observer} from 'mobx-react';
import {action, makeObservable, observable} from 'mobx';
import {Avatar, ListItemIcon, Menu as PopMenu, MenuItem} from '@material-ui/core/';

import './user_box.css';

import LogoutIcon from '@material-ui/icons/LockOpen';
import {UserContext} from "../containers/contexts/user_ctx";

import DefaultAvatarImg from '../static/avatar.jpg';


export interface UserBoxProps {
	insideDrawer?: boolean;
	onLogout: () => void;
}

export interface UserBoxState {
}

@observer
export class UserBox extends Component<UserBoxProps, UserBoxState> {
	static contextType = UserContext; // To use multiple context use func component

	userMenuOpen: boolean = false;
	anchorEl: any;

	constructor( props: any, context: any ) {
		super( props, context );

		makeObservable(this, {
			anchorEl: observable,
			userMenuOpen: observable,
		});
	}

	changeUserMenuOpenState = action( ( event: any ) => {
		this.anchorEl = event.currentTarget;
		this.userMenuOpen = !this.userMenuOpen;
	} );

	render() {
		const { user } = this.context;

		// const avatar = userAvatar ? `${(window as any).config.CDN}/${userAvatar}` : DefaultAvatarImg;
		const avatar = DefaultAvatarImg;

		return (
			<div className="UserBox"
			     style={this.props.insideDrawer ? { display: 'block', top: 0, paddingTop: 15, height: 85 } : {}}>
				<div onClick={this.changeUserMenuOpenState} className="UserBoxContent">
					<div className="UserBoxAvatar">
						<Avatar
							className="UserBoxAvatar"
							src={avatar}
							// style={{ border: `2px solid ${primary[ 700 ]}` }}
						/>
					</div>
					<div className="UserBoxInfo">
						<span className="Username">{user.username}</span>
						<span className="EMail">{user.email}</span>
					</div>
				</div>

				<PopMenu
					anchorEl={this.anchorEl}
					open={this.userMenuOpen}
					onClose={this.changeUserMenuOpenState}
					classes={{ "paper": "UserBoxMenu" }}
				>
					<MenuItem onClick={this.props.onLogout}><ListItemIcon><LogoutIcon/></ListItemIcon>{( "Logout" )}
					</MenuItem>
				</PopMenu>
			</div>
		);
	}
}

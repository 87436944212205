import * as React from 'react';
import {Component} from 'react';
import Wrapper from '../../../components/public/wrapper';
import CreatePasswordForm from '../../../components/public/create_password_form';
import {observer} from 'mobx-react';
import {Box} from "@material-ui/core";

@observer
class CreatePwd extends Component<any, any> {
	render() {

		return (
			<Wrapper>
				<Box className="LandingPanelDescription">Create password</Box>

				<CreatePasswordForm token={this.props.match.params.token} username={this.props.match.params.username} />
			</Wrapper>
		);
	}
}

export default CreatePwd;

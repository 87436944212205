import * as React from 'react';
import {Component} from 'react';
import {observer} from 'mobx-react';
import {List, ListSubheader} from '@material-ui/core';

import './app_menu.css';

interface AppMenuProps {
	insideDrawer?: boolean;
	bottomItems: any;
}

interface AppMenuState {

}

// @inject( "user" )
@observer
class AppMenu extends Component<AppMenuProps, AppMenuState> {
	render() {
		return (
			<div className={`LeftMenu ${this.props.insideDrawer ? 'DrawerLeftMenu' : ''}`}>
				<div className="MenuBody">
					<div>
						<List>
							<ListSubheader>Menu</ListSubheader>
							{this.props.children}
						</List>
					</div>

					{/*<div>*/}
						{/*<List>*/}
							{/*<ListSubheader>Extras</ListSubheader>*/}
							{/*{this.props.bottomItems}*/}
						{/*</List>*/}
					{/*</div>*/}
				</div>
			</div>
		);
	}
}

export default AppMenu;

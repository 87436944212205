import * as _ from 'lodash';
import * as React from 'react';
import {Component, useContext} from 'react';
import {observer} from 'mobx-react';
import Grid from '@material-ui/core/Grid';
import styled from 'styled-components';
import {Fab, IconButton, Menu, MenuItem} from '@material-ui/core';
import ZoomOutMapOutlinedIcon from '@material-ui/icons/ZoomOutMapOutlined';

import './screen_header.css';
import {makeObservable, observable} from "mobx";
import {theme} from "../index";
import {SnackbarsContext} from "../containers/contexts/snackbar_ctx";
import {FullscreenContext} from "../containers/contexts/fullscreen_ctx";

const ScreenHeaderActionButtonContainer = styled.div`
	width: 100px;
	margin-left: auto;
	margin-top: 25px;
`;

const ScreenHeaderActionButton = styled.div`
	position: absolute;
	z-index: 50px;
`;

export type FABAction = () => void;

export interface FABActionItem {
	onAction: FABAction;
	name: string;
}


export interface ScreenHeaderFABItemProps {
	action: FABActionItem,
	onClick: () => void;
}

@observer
export class ScreenHeaderFABItem extends Component<ScreenHeaderFABItemProps, {}> {

	public render() {

		return (
			<MenuItem onClick={this.handleClick}>
				{this.props.action.name}
			</MenuItem>
		);
	}

	private handleClick = ( event: any ) => {
		this.props.onClick();
		setTimeout( () => {
			this.props.action.onAction();
		}, 350 ) // Do action after menu hide animation
	};
}


export interface ScreenHeaderProps {
	name: string,
	icon: any,
	actionIcon?: any,
	onAction?: FABAction | FABActionItem[],
	disabled?: boolean;
}

@observer
export class ScreenHeader extends Component<ScreenHeaderProps, {}> {
	public static defaultProps: Partial<ScreenHeaderProps> = {
		disabled: false
	};

	static contextType = FullscreenContext; // To use multiple context use func component

	menuOpen: boolean = false;
	anchorEl: any = null;

	constructor( props: any, context: any ) {
		super( props, context );

		makeObservable(this, {
			menuOpen: observable,
			anchorEl: observable,
		});
	}

	public renderAction() {
		let Icon = this.props.actionIcon;

		return (
			<ScreenHeaderActionButtonContainer>
				<ScreenHeaderActionButton>
					<Fab
						onClick={!this.props.disabled ? (_.isFunction( this.props.onAction ) ? this.props.onAction : this.handleMenuClick ) : () => {
						}}
						color="secondary"
						disabled={this.props.disabled}
						style={this.props.disabled ? {
							backgroundColor: '#ccc',
							boxShadow: 'none',
							zIndex: 50
						} /*FAB disabled FIX*/ : { zIndex: 50 }}
					>
						<Icon/>
					</Fab>
					<Menu
						anchorEl={this.anchorEl}
						open={this.menuOpen}
						onClose={this.handleMenuClose}
					>
						{_.isArray( this.props.onAction ) && this.props.onAction.map( ( action: FABActionItem ) =>
							<ScreenHeaderFABItem key={action.name} action={action} onClick={this.handleMenuClose}>
								{action.name}
							</ScreenHeaderFABItem>,
						)}
					</Menu>
				</ScreenHeaderActionButton>
			</ScreenHeaderActionButtonContainer>
		)
	}

	public render() {
		let Icon = this.props.icon;

		return (
			<Grid item={true} xs={12} className="ViewHeader">
				<Icon style={{ width: 32, height: 32, color: theme.palette.secondary.main}}/>
				<span className="ViewHeaderName">
					{this.props.name}

					&nbsp;&nbsp;

					<IconButton
						color="secondary"
						aria-label="Change fullscreen mode"
						component="span"
						style={{marginTop: -8, position: "absolute"}}
						onClick={this.handleFullScreenClick}
					>
						<ZoomOutMapOutlinedIcon />
					</IconButton>
				</span>

				{this.props.actionIcon && this.renderAction()}
			</Grid>
		);
	}

	private handleFullScreenClick = ( event: any ) => {
		if( this.context.fullscreen  ) {
			this.context.close();
		} else {
			this.context.open();
		}
	};

	private handleMenuClick = ( event: any ) => {
		this.anchorEl = event.currentTarget;
		this.menuOpen = true;
	};

	private handleMenuClose = () => {
		this.menuOpen = false;
	};
}

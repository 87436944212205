import {action, computed, makeObservable, observable} from "mobx";
import {BaseModel} from "../utils/base_model";

export enum AccessLevel {
	normal =  0,
	moderator = 500,
	admin = 1000,
}

export const humanAccessLevel: {
	[key in AccessLevel]: string
} = {
	[AccessLevel.normal]: "Standard user",
	[AccessLevel.moderator]: "Moderator",
	[AccessLevel.admin]: "Superuser",
}


export default class User extends BaseModel {
	id?: number;
	username?: string;
	email?: string;
	avatar?: string;
	lastLogin?: Date;
	passwordUpdatedAt?: Date;
	isActive?: boolean;
	accessLevel?: AccessLevel;

	constructor( json: any ) {
		super();

		makeObservable(this, {
			id: observable,
			username: observable,
			email: observable,
			avatar: observable,
			lastLogin: observable,
			passwordUpdatedAt: observable,
			isActive: observable,
			accessLevel: observable,

			isAdmin: computed,
			isModerator: computed,
			humanAccessLevel: computed,
		});

		this.deserialize( json );
	}

	get isAdmin() {
		return ( this.accessLevel ?? 0 ) >= AccessLevel.admin;
	}

	get isModerator() {
		return ( this.accessLevel ?? 0 ) >= AccessLevel.moderator;
	}

	get humanAccessLevel(): string {
		return humanAccessLevel[ this.accessLevel ?? AccessLevel.normal ]
	}

	deserialize = action( ( json: any ) => {
		this.deserializeFields( json, [ 'id', 'username', 'email', 'isActive', 'accessLevel' ] );
		if( json.lastLogin ) {
			this.lastLogin = new Date( json.lastLogin );
		}

		if( json.passwordUpdatedAt ) {
			this.passwordUpdatedAt = new Date( json.passwordUpdatedAt );
		}
	} )


}

import * as React from 'react';
import {FunctionComponent, SyntheticEvent} from 'react';
import {observer} from 'mobx-react';
import {Button, TextField} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import {FormDialogProps} from "../form_dialog_wrapper";
import {useForm} from "react-hook-form";
import {Spacer} from "../spacer";


export const CreateUserForm: FunctionComponent<FormDialogProps> = observer( ( props: FormDialogProps ) => {
	const { register, handleSubmit } = useForm();

	const onSubmit = async ( e: SyntheticEvent ) => {
		await handleSubmit(async ( data: any ) => {
			await props.onSubmit( data.username, data.email );
			e.preventDefault();
		})(e);
	};

	return (
		<form onSubmit={onSubmit}>
			<Grid container={true}>
				<Grid item={true} xs={12}>
					<TextField
						inputRef={register()}
						variant="outlined"
						type="text"
						name="username"
						placeholder="username"
						label="username"
						autoFocus={true}
						fullWidth={true}
						required={true}
						inputProps={{minLength: 3, maxLength: 30}}
					/>

					<Spacer height={16} />

					<TextField
						inputRef={register()}
						variant="outlined"
						type="email"
						name="email"
						placeholder="email"
						label="email"
						fullWidth={true}
						required={true}
					/>
				</Grid>

				<Spacer height={16} />

				<Grid item={true} xs={12} style={{ textAlign: 'right' }}>
					<Button onClick={props.onCancel} disabled={props.processing}>
						Cancel
					</Button>
					<Button
						type="submit"
						color="secondary"
						disabled={props.processing}
					>
						Create new user
					</Button>
				</Grid>
			</Grid>
		</form>
	)
} );

export default CreateUserForm;

import * as _ from 'lodash';
import * as React from 'react';
import {Component} from 'react';
import {observer} from 'mobx-react';
import Button from '@material-ui/core/Button';
import styled, {keyframes} from 'styled-components';
import EmptyListIcon from '@material-ui/icons/Inbox';
import Typography from '@material-ui/core/Typography';
import Menu from '@material-ui/core/Menu';
import {FABAction, FABActionItem, ScreenHeaderFABItem} from "../screen_header";
import {makeObservable, observable} from "mobx";

const fadeIn = keyframes`
	from { opacity: 0 }
	33% { opacity: 0 }
	100% { opacity: 1 }
`;

export const EmptyListWrapper = styled.div`
	width: 100%;
	text-align: center;
	animation: ${fadeIn} 1.5s;
`;

export interface EmptyListProps {
	icon?: any;
	action?: FABAction | FABActionItem[],
}

@observer
export class EmptyList extends Component<EmptyListProps, {}> {
	menuOpen: boolean = false;
	anchorEl: any = null;

	constructor( props: any, context: any ) {
		super( props, context );

		makeObservable(this, {
			menuOpen: observable,
			anchorEl: observable,
		});
	}

	public render() {
		const Icon = this.props.icon;

		return (
			<EmptyListWrapper>
				<EmptyListIcon style={{ color: '#ccc', width: '40%', height: '40%' }}/><br/>
				<Typography variant="h5" gutterBottom={true}>
					{this.props.children}
				</Typography>

				{
					( this.props.action && this.props.icon ) &&
					<Typography variant="subtitle1">
						Use the "
						<Button
							onClick={_.isFunction( this.props.action ) ? this.props.action : this.handleMenuClick}
							size="small"
							style={{ minWidth: 20, minHeight: 20, padding: 3 }}
						>
							<Icon style={{ width: 18, height: 18 }}/>
						</Button>
						" button to add a new item
					</Typography>
				}

				<Menu
					anchorEl={this.anchorEl}
					open={this.menuOpen}
					onClose={this.handleMenuClose}
				>
					{_.isArray( this.props.action ) && this.props.action.map( ( action: FABActionItem ) =>
						<ScreenHeaderFABItem key={action.name} action={action} onClick={this.handleMenuClose}>
							{action.name}
						</ScreenHeaderFABItem>,
					)}
				</Menu>

			</EmptyListWrapper>
		);
	}

	private handleMenuClick = ( event: any ) => {
		this.anchorEl = event.currentTarget;
		this.menuOpen = true;
	};

	private handleMenuClose = () => {
		this.menuOpen = false;
	};

}
import * as React from 'react';
import {FunctionComponent} from 'react';

import {Box, Button, Link, TextField} from '@material-ui/core';
import {Link as A} from 'react-router-dom';
import {passwordRegex} from "../../utils/regexes";
import Typography from '@material-ui/core/Typography';
import {observer} from "mobx-react";
import {useForm} from "react-hook-form";

import pdf from '../../static/2fa_instruction.pdf';

export const LoginForm: FunctionComponent<{}> = observer( () => {

	const { register } = useForm();

	return (
		<div className="LandingPanelForm">
			<form method="post" action="/api/v1/auth/login">
				<div className="LandingPanelInput">
					<TextField
						inputRef={register()}
						type="text"
						name="login"
						placeholder="Login"
						label="Login"
						autoFocus={true}
						variant="outlined"
						fullWidth={true}
						required={true}
					/>
				</div>

				<div className="LandingPanelInput">
					<TextField
						inputRef={register()}
						type="password"
						name="password"
						placeholder="Password"
						label="Password"
						variant="outlined"
						fullWidth={true}
						required={true}
						inputProps={{minLength: 8, maxLength: 30, pattern: passwordRegex.source}}
					/>
				</div>

				<div className="LandingPanelInput">
					<TextField
						inputRef={register()}
						type="password"
						name="otp_code"
						placeholder="Two factor auth code"
						label="Code from your authentication app"
						variant="outlined"
						fullWidth={true}
						inputProps={{minLength: 6, maxLength: 6}}
					/>
				</div>

				<div className="LandingPanelInput">
					<Button type="submit" variant="contained" color="secondary" fullWidth={true}>
						Login
					</Button>
				</div>

				<Box>
					<Typography variant="body2">
						<Link component={A} to="reset-password">
							Forgot your password?
						</Link>

						&nbsp;|&nbsp;

						<Link component="a" href={pdf} target="_blank">
							Help
						</Link>
					</Typography>
				</Box>

			</form>
		</div>
	);
} );

export default LoginForm;

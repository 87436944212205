import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './app';
import {createMuiTheme, ThemeProvider,} from '@material-ui/core/styles';
import {SnackbarManager} from "./containers/contexts/snackbar_ctx";
import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import {FullscreenManager} from "./containers/contexts/fullscreen_ctx";


export const theme = createMuiTheme({
	palette: {
		primary: {
			main: '#36302f',
			contrastText: '#fff',
		},
		secondary: {
			main: '#c29d67',
			contrastText: '#fff',
		},
		error: {
			main: '#e60012',
			contrastText: '#fff',
		}
	},
	overrides: {
		"MuiFilledInput": {
			root: {
				// backgroundColor: '#fff',
			},
			focused: {
				// backgroundColor: '#eee',
			}
		},
		"MuiTableCell": {
			root: {
				paddingTop: 4,
				paddingBottom: 4,
				height: 40,
			}
		}
	}
});

ReactDOM.render(
	<>
		<ThemeProvider theme={theme}>
			<MuiPickersUtilsProvider utils={MomentUtils}>
				<SnackbarManager>
					<FullscreenManager>
						<App />
					</FullscreenManager>
				</SnackbarManager>
			</MuiPickersUtilsProvider>
		</ThemeProvider>
	</>,
	document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);

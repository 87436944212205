import * as React from 'react';
import {Component} from 'react';
import {observer} from 'mobx-react';
import {action, makeObservable, observable} from "mobx";
import axios from 'axios';

import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import {FormDialogWrapper} from "../components/form_dialog_wrapper";
import ChangeUsernameForm from "../components/forms/change_username_form";
import {UserContext} from "./contexts/user_ctx";
import {Box} from "@material-ui/core";
import {Snackbars} from "./contexts/snackbar_ctx";

interface ChangeUsernameProps {
	notifications: Snackbars;
}



@observer
export default class ChangeUsername extends Component<ChangeUsernameProps, {}> {
	static contextType = UserContext; // To use multiple context use func component
	processing: boolean = false;

	constructor( props: any, context: any ) {
		super( props, context );

		makeObservable(this, {
			processing: observable,
		});
	}

	private changeUsernameDialogRef: FormDialogWrapper | null = null;

	public render() {
		return (
			<Box>
				<IconButton onClick={this.changeUsername}>
					<EditIcon />
				</IconButton>

				<FormDialogWrapper
					ref={this.setChangeUsernameDialogRef}
					name="Change username"
					form={ChangeUsernameForm}
					onSubmit={this.processChangeUsername}
					onCancel={this.onHide}
					processing={this.processing}
					formValues={{username: this.context.user.username}}
				/>
			</Box>
		);
	}

	private setChangeUsernameDialogRef = ( ref: FormDialogWrapper ) => {
		this.changeUsernameDialogRef = ref;
	};

	private changeUsername = () => {
		this.changeUsernameDialogRef?.show();
	};

	private onHide = async () => {
		this.changeUsernameDialogRef?.hide( null );
	};

	private processChangeUsername = async ( newUsername: string ) => {
		if( !this.processing ) {
			try {
				action( () => {
					this.processing = true;
				} )()

				const resp = await axios( {
					method: 'patch',
					url: '/api/v1/users/change-username',
					data: {
						username: newUsername
					}
				} );

				if( resp.data.success ) {
					action( () => {
						this.context.user.username = newUsername;
						this.props.notifications.showSnackbar( "Username has been changed successfully" );
						this.changeUsernameDialogRef?.hide( null, true );
					} )()
				} else {
					throw new Error( "Invalid response" );
				}
			} catch( err ) {
				console.error(err);
				this.props.notifications.showSnackbar( "Can not change username", "error" );
			} finally {
				action( () => {
					this.processing = false;
				} )()
			}
		}
	};

}
import * as React from 'react';
import {FunctionComponent} from 'react';

import {Box, Button, Link, TextField} from '@material-ui/core';
import {Link as A} from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import {observer} from "mobx-react";
import {useForm} from "react-hook-form";


export const CreatePasswordForm: FunctionComponent<{}> = observer( () => {
	const { register } = useForm();

	return (
		<div className="LandingPanelForm">
			<form method="post" action="/api/v1/auth/reset-password">
				<div className="LandingPanelInput">
					<TextField
						inputRef={register()}
						type="text"
						name="login"
						placeholder="Login"
						label="Login"
						autoFocus={true}
						variant="outlined"
						fullWidth={true}
						required={true}
					/>
				</div>

				<div className="LandingPanelInput">
					<Button type="submit" variant="contained" color="secondary" fullWidth={true}>
						Reset password
					</Button>
				</div>

				<Box>
					<Typography variant="body2">
						<Link component={A} to="/">
							Back to login page
						</Link>
					</Typography>
				</Box>

			</form>
		</div>
	);
} );

export default CreatePasswordForm;
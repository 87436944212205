import * as React from 'react';
import {Component} from 'react';
import Wrapper from '../../../components/public/wrapper';
import {observer} from 'mobx-react';
import {Box} from "@material-ui/core";
import CreateOtpForm from "../../../components/public/create_otp_form";

@observer
class CreateNewOtpScreen extends Component<any, any> {
	render() {

		return (
			<Wrapper>
				<Box className="LandingPanelDescription">Create new two factor auth token</Box>

				<CreateOtpForm token={this.props.match.params.token} username={this.props.match.params.username} />
			</Wrapper>
		);
	}
}

export default CreateNewOtpScreen;
